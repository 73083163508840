import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["selectAll", "counterContainer", "counter", "assigners",
    "mainCheck", "subCheck", "form", "assignAttributeField", "assignValueField",
    "totalCounter", "hideable"]

  connect(){
    this.updateCounter()
  }

  toggle(event){
    if (this.mainCheckTarget.checked) {
      this._setAllCheckboxesTo(true);
    } else {
      this._setAllCheckboxesTo(false);
    }
    this.updateCounter();
  }

  updateCounter(){
    const selectedCounter = this._getSelectedCounter();
    const totalCounter = this._getTotalCounter();

    this._handleVisibility(selectedCounter);
    this.counterTarget.innerHTML = selectedCounter;
    this.totalCounterTarget.innerHTML = totalCounter;
  }

  assign(event){
    const numberOfUpdates = this._getSelectedCounter()
    let baseConfirmMessage = I18n.are_you_sure_bulk_update
    let confirmAssign;


    if (numberOfUpdates == 1) {
      confirmAssign = true
    } else {
      confirmAssign = confirm(baseConfirmMessage.replace("#", `${this._getSelectedCounter()}`))
    }

    if (confirmAssign) {
      const assignAttribute = event.target.dataset.assignAttribute;
      const assignValue = event.target.dataset.assignValue;

      this.assignAttributeFieldTarget.value = assignAttribute;
      this.assignValueFieldTarget.value = assignValue;

      Rails.fire(this.formTarget, 'submit');
    }
  }

  // private

  _handleVisibility(counter){
    if (counter > 0) {
      this.counterContainerTarget.classList.remove("hidden");
      this.assignersTarget.classList.remove("hidden");
      this.selectAllTarget.classList.add("hidden");
      if(this.hasHideableTarget) { this.hideableTarget.classList.add("hidden"); }
    } else {
      this.counterContainerTarget.classList.add("hidden");
      this.assignersTarget.classList.add("hidden");
      this.selectAllTarget.classList.remove("hidden");
      if(this.hasHideableTarget) { this.hideableTarget.classList.remove("hidden"); }
    }
  }

  _getSelectedCounter(){
    let counter = 0;
    this.subCheckTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        counter += 1;
      }
    })
    return counter;
  }

  _getTotalCounter(){
    return this.subCheckTargets.length;
  }

  _setAllCheckboxesTo(value){
    this.subCheckTargets.forEach((checkbox) => {
      checkbox.checked = value;
    })
  }

}
