import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import Variable from './Variable.jsx'

export function replaceShortcuts(str) {
  return str.replace(/@@(\w+)@@/g, '<variable field-id="$1" contenteditable="true">...</variable>')
}

export default Node.create({
  name: "variable",

  group: "inline",

  content: "text*",

  inline: true,

  addAttributes() {
    return {
      type: {
        default: ""
      },
      key: {
        default: "",
        parseHTML: (element) => element.getAttribute("field-id"),
      },
      fieldLabel: {
        default: "",
        parseHTML: (element) => element.getAttribute("field-label"),
      },
      contenteditable: {
        default: false
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "variable",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["variable", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Variable);
  },
});
