import CommonsController from "./commons_controller";
import { BarChartSplit } from "gobierto-vizzs";
import { timeFormatDate, isDataTotalEmpty, getQuarter, sortingTooltipFn } from "../../helpers/vizzs_report";

/**
* This controller is for data parsing. The bar chart stacked
* is rendered via the npm-package gobierto-vizzs.
* Add this data attribute to load the data.
* Add it to the element where you define the controller:
    @data-attribute: data-visualizations--distribution-csv-endpoint-value="url"

  (Optional) Add this data attribute where the chart will be rendered:
    @data-attribute: data-config="<%= config.as_json %>"

* @markup-example:

  <% config = [ "contract_type", "month", "absolute" ] %>

  <div
    data-controller="visualizations--distribution"
    data-visualizations--distribution-csv-endpoint-value="data"
  >
    <select
      data-action="visualizations--distribution#selectType"
      data-visualizations--distribution-target="typeSelector"
    >
      <option value="process_type"><%= Tender.human_attribute_name(:process_type) %></option>
      <option value="number_of_proposals"><%= Tender.human_attribute_name(:number_of_proposals) %></option>
    </select>

    <div class="tabs-filters" data-action="click->visualizations--distribution#selectDate">
      <button class="tab-item" data-visualizations--distribution-target="dateSelector" value="month" aria-checked="true">
        <%= Tender.human_attribute_name(:month) %>
      </button>
      <button class="tab-item" data-visualizations--distribution-target="dateSelector" value="quarter">
        <%= Tender.human_attribute_name(:quarter) %>
      </button>
      <button class="tab-item" data-visualizations--distribution-target="dateSelector" value="year">
        <%= Tender.human_attribute_name(:year) %>
      </button>
    </div>

    <div data-visualizations--distribution-target="chart" data-config="<%= evolution_contract_type_month.as_json %>"></div>
    <div style="display: none;">No data</div>
  </div>
**/

export default class extends CommonsController {
  selectType({ target }) {
    if (this.currentType === target.value) return null

    this.currentType = target.value;
    this._update({ typeSelector: this.currentType })
  }

  selectDate({ target }) {
    if (this.currentDate === target.value) return null

    this.currentDate = target.value;

    this.dateSelectorTargets.forEach((elem) =>
      elem === target ? target.setAttribute("aria-checked", true) : elem.removeAttribute("aria-checked")
    );

    this._update({ dateSelector: this.currentDate })
  }

  _loadChart(element, data) {
    const { config } = element.dataset

    const typeSelector = config ? JSON.parse(config)[0] : this.getType();
    const dateSelector = config ? JSON.parse(config)[1] : this.getDate();

    const { parsedData, categories, series, tickValues } = this._helper(data, { typeSelector, dateSelector });

    // Shows the "no data" indicator
    if (isDataTotalEmpty(parsedData)) {
      element.nextElementSibling.style.display = null
      return null
    }

    // Do not pass the "data" to the chart to avoid the DOM blocked
    const chart = new BarChartSplit(element, [], {
      x: typeSelector,
      y: dateSelector,
      count: this.DEFAULT_COUNT_PROPERTY,
      moveLabels: false,
      yTickFormat: d => timeFormatDate("year")(new Date(d)),
      tooltip: this._tooltip,
      categories,
      series,
      yTickValues: tickValues
    });

    return [chart, parsedData]
  }

  _update({ typeSelector = this.currentType, dateSelector = this.currentDate }) {
    const { parsedData, categories, series, tickValues } = this._helper(this.initialData, { typeSelector, dateSelector });

    this.visualization.setX(typeSelector)
    this.visualization.setY(dateSelector)
    this.visualization.setCategories(categories)
    this.visualization.setSeries(series)
    this.visualization.setYTickValues(tickValues)
    this.visualization.setData(parsedData)
  }

  _tooltip(item) {
    const { selected_type, selected_date } = item;

    const __data = this.data
      .filter((x) => x[selected_date] === item[selected_date])
      .map((elem) => ({
        name: elem[selected_type],
        count: elem.count,
        sum: elem[this.countProp],
      }))

    // apply the proper sorting method regarding the typeSelector
    const data = sortingTooltipFn(this.xAxisProp)(__data)

    const totals = {
      name: I18n.charts.bar_chart_evolution_distribution.total,
      sum: data.reduce((acc, { sum }) => acc + sum, 0),
      count: data.reduce((acc, { count }) => acc + count, 0),
      header: true
    }

    const sortFilteredData = [totals, ...data].map(({ name, count, sum, header = false }) => {
      const tdHeader = `<span class="text-tiny text-black font-bold inline-block align-middle">${name}</span>`
      const tdRegular = `<span class="text-tiny text-black inline-block align-middle">${name}</span>`
      const tdCount = `<span class="text-tiny text-black">${count}</span>`
      const tdSum = `<span class="text-tiny text-black">${new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(sum)}</span>`

      return `
      <tr>
        <td>${header ? tdHeader : tdRegular}</td>
        <td class="text-right">${tdCount}</td>
        <td class="text-right">${tdSum}</td>
      </tr>`
    }).join(" ");

    return `
      <span class="font-bold">${item[this.yAxisProp]}</span>
      <div class="items-list-container">
        <table class="items-list align-top-cells">
          <thead>
            <tr>
              <th></th>
              <th style="text-align: right">${I18n.charts.bar_chart_evolution_distribution.number_of_contracts}</th>
              <th style="text-align: right">${I18n.charts.bar_chart_evolution_distribution.sum}</th>
            </tr>
          </thead>
          <tbody>
            ${sortFilteredData}
          </tbody>
        </table>
      </div>
    `
  }

  _parseData(data, { typeSelector, dateSelector, oldestAllowedDate }) {
    return (
      data
        // Ignore the oldest received data
        .filter((x) => new Date(oldestAllowedDate) < new Date(x.submission_date))
        .map(({ submission_date, initial_amount_no_taxes = 0, ...rest }) => {
          const sum = +initial_amount_no_taxes || 0;
          const [year, month] = submission_date.split("-");

          return {
            year,
            month: `${year}-${month}`,
            quarter: getQuarter(submission_date),
            submission_date: submission_date,
            initial_amount_no_taxes: sum,
            selected_date: dateSelector,
            selected_type: typeSelector,
            count: 1,
            ...rest,
          };
        })
    );
  }
}
