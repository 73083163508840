import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "link", "numberExtensionsSelect", "extensionBlock" ]
  static values = { afterId: String }

  connect(){
    this.reviewLinkVisibility();
  }

  reviewLinkVisibility(event){
    if (this._extensionsBlocksCount() >= this._extensionsSelectNumber()){
      this.linkTarget.classList.add("hidden");
    } else {
      this.linkTarget.classList.remove("hidden");
    }
  }

  addAndHide(event){
    event.preventDefault();

    // Hide link
    event.target.classList.add("hidden");

    // Add template
    const content = this.templateTarget.innerHTML;

    const afterElement = document.querySelector(`#${this.afterIdValue}`)
    afterElement.insertAdjacentHTML('afterend', content)
  }

  // private

  _extensionsBlocksCount(){
    return this.extensionBlockTargets.filter(function(item){
      return !item.classList.contains('hidden')
    }).length;
  }

  _extensionsSelectNumber(){
    const selectValue = this.numberExtensionsSelectTarget.value;

    if (this.numberExtensionsSelectTarget.value == '') {
      return 0;
    } else {
      return parseInt(this.numberExtensionsSelectTarget.value, 10);
    }
  }
}
