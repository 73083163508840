import Mention from '@tiptap/extension-mention';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Typography from '@tiptap/extension-typography';
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import "./OnlineEditor.css";
import Toolbar from "./components/Toolbar.jsx";
import suggestion from "./components/extension-suggestion.js";
import Variable from './components/extension-variable.js'
import CustomParagraph from './components/extension-paragraph.js'

export default function OnlineEditor({ id, content, ...params }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      CustomParagraph,
      Table,
      TableRow,
      TableHeader,
      TableCell,
      Typography,
      Mention.configure({
        HTMLAttributes: {
          class: 'button-rounded-mini',
        },
        suggestion,
      }),
      Variable.configure({
        HTMLAttributes: {
          id,
          ...params
        },
      })
    ],
    // editable: false,
    content
  });

  const saveEditor = () => {
    if (!editor) return null

    localStorage.setItem(id, JSON.stringify(editor.getJSON()))
  }

  useEffect(() => {
    window.addEventListener("save-document", saveEditor);
    return () => {
      window.removeEventListener("save-document", saveEditor);
    };
  }, [editor]);

  if (!editor) return <></>

  return (
    <>
      <BubbleMenu editor={editor}>
        <Toolbar editor={editor} />
      </BubbleMenu>
      <EditorContent editor={editor}></EditorContent>
    </>
  );
}
