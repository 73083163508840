import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {newUrl: String, condition: Boolean}

  connect() {
  }

  updateUrl(){
    Turbo.navigator.history.replace({ href: this.newUrlValue })
  }
}
