import Paragraph from '@tiptap/extension-paragraph'

export default Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      contenteditable: {
        default: null
      },
    }
  },
})
