import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "singleDigitInput", "mainInput" ]

  connect(){
    this._add_paste_listener();
    //Only for the strange case where the user press a key and reloads the app
    this.singleDigitInputTargets[0].select()
  }

  handleKeyUp(event){
    const { target, key } = event;
    const nextInputElement = document.querySelector(`input#${event.currentTarget.dataset.next}`);

    // We update the hidden input first.
    // Even if moving the focus cursor fails, at least the input is updated.
    this._updateMainInput();

    const isNumber = /^[0-9]$/i.test(key)
    const isTab = key === "Tab";
    if (!isTab && !isNumber) {
      event.preventDefault();
      target.select()
      return false;
    }

    if (isTab) {
      return true;
    }

    if (nextInputElement) {
      nextInputElement.focus();
      nextInputElement.select();
    }
  }


  // private

  _updateMainInput() {
    let otpCode = ''

    this.singleDigitInputTargets.forEach((element, index) => {
      otpCode += element.value
    });

    this.mainInputTarget.value = otpCode
  }

  _add_paste_listener(){
    document.addEventListener("paste", (event) => {
      // if the target is a text input
      if (event.target.type === "text") {
        var data = event.clipboardData.getData('Text');
        // split clipboard text into single characters
        data = data.split('');
        // set the value in all other text inputs
        this.singleDigitInputTargets.forEach((element, index) => {
          element.value = data[index];
        });

        this._updateMainInput();
      }
    });
  }

}
