import { Controller } from "@hotwired/stimulus";

/**
* This controller is used to create dropdowns.
* You can choose between two events to open the dropdown,
* with a click or mouseover.
* You can nest as many dropdowns as you want. In
* 'app/views/sandbox/_nav-menu.html.erb' you can view
* an example that contains nested dropdowns.
*
* @markup-example
* @event -> mouseeover:
*
* <div class="relative"
*    data-controller="dropdown-menu"
*    data-action="mouseenter->dropdown-menu#showDropdown mouseleave->dropdown-menu#hideDropdown"
* >
* <button
*   data-action="mouseenter->dropdown-menu#showDropdown",
*   data-dropdown-menu-target="toggleButton"/>
*   BUTTON
* </button>
* <div
*   class="dropdown-menu dropdown-container top-0 shadow-xl rounded opacity-0 hidden"
*   data-dropdown-menu-target="toggleContainer"
*   data-action="mouseenter->dropdown-menu#showDropdown"
* >
* </div>
*
* @markup-example
* @event -> click:
*
* <div class="relative"
*    data-controller="dropdown-menu"
*    data-action="click@window->dropdown-menu#checkTargetDropdown"
* >
* <button
*   data-action="click->dropdown-menu#showDropdown",
*   data-dropdown-menu-target="toggleButton"/>
*   BUTTON
* </button>
* <div
*   class="dropdown-menu absolute right-0 top-0 shadow-xl rounded opacity-0 hidden"
*   data-dropdown-menu-target="toggleContainer"
* >
* </div>
*
*
*/

export default class extends Controller {
  static targets = ["toggleButton", "toggleContainer"]
  static values = {
    turboLoadUrl: String,
    alreadyTurboLoaded: {
      type: Boolean,
      default: false
    },
    openingDelay: {
      type: Number,
      default: 300
    },
    closingDelay: {
      type: Number,
      default: 300
    },
    visible: {
      type: String,
      default: "open active"
    },
    invisible: {
      type: String,
      default: "hidden close opacity-0"
    }
  }

  connect() {
    // CSS Classes would be more appropiate, https://stimulus.hotwired.dev/reference/css-classes
    // but they don't provide "default" settings, https://github.com/hotwired/stimulus/issues/458
    // However, for Values (https://stimulus.hotwired.dev/reference/values#default-values) it's required to do this:
    this.VISIBLE_CLASSES = this.visibleValue.split(" ")
    this.INVISIBLE_CLASSES = this.invisibleValue.split(" ")
  }

  toggleContainerTargetConnected(element) {
    if (element.classList.contains("open")) {
      this._isContentOffViewport()
    }
  }

  showDropdown(event) {
    event.preventDefault();

    const callback = () => {
      if (this.hasTurboLoadUrlValue && !this.alreadyTurboLoadedValue) {
        this._turboLoadContainer();
      } else {
        // TODO:
        // Once checkTargetDropdown will be removed, this timeout can be dismissed as well.
        // Currently, it sets a timeout of 0ms, to take this block out of the main execution thread
        // and run this content asynchronously.
        // Doing this, this.toggleContainerTarget.classList.contains("active") will be false in checkTargetDropdown
        setTimeout(() => {
          this.toggleContainerTarget.classList.remove(...this.INVISIBLE_CLASSES);
          this.toggleContainerTarget.classList.add(...this.VISIBLE_CLASSES);
          this._isContentOffViewport();
        }, 0);
      }
    }

    // Run the callback immediately when the event is clicked,
    // otherwise, set a timeout
    if (event.type === "click") {
      return callback()
    }

    // Cancel multiple showDropdown events
    if (this.timeout) { clearTimeout(this.timeout); }

    // Postpone the event
    this.timeout = setTimeout(callback, this.openingDelayValue);
  }

  // TODO:
  // remove this method, it creates too much listeners everywhere
  // The way to do this is about setting a listener (to the document)
  // ONLY when the dropdown is shown, and remove this listener when is hidden
  checkTargetDropdown({ target }) {
    if (
      !this.toggleContainerTarget.contains(target) &&
      this.toggleContainerTarget.classList.contains("active") &&
      !target.classList.contains("js-skip-dropdown-trigger") &&
      target.type !== "submit"
    ) {
      this.hideDropdown();
    }
  }

  hideDropdown() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    // Set the same timeout for closing the element, consecutive events will be cancelled
    this.timeout = setTimeout(() => {
      this.toggleContainerTarget.classList.remove(...this.VISIBLE_CLASSES);
      this.toggleContainerTarget.classList.add(...this.INVISIBLE_CLASSES);
    }, this.closingDelayValue);
  }

  _isContentOffViewport() {
    const toggleContainerOffsets = this.toggleContainerTarget.getBoundingClientRect();

    if(toggleContainerOffsets.width + toggleContainerOffsets.left > document.documentElement.clientWidth) {
      this.toggleContainerTarget.classList.add("dropdown-container-right");
    } else {
      this.toggleContainerTarget.classList.add("dropdown-container-left");
    }

    if(toggleContainerOffsets.height + toggleContainerOffsets.y > window.innerHeight) {
      this.toggleContainerTarget.classList.add("scroll-mb-6");
      this.toggleContainerTarget.scrollIntoView({
        block: "end",
        behavior: "smooth"
      })
    }
  }

  _turboLoadContainer(){
    fetch(this.turboLoadUrlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))

    this.alreadyTurboLoadedValue = true;
  }
}
