import React from "react";
import Pencil from "../../../../../public/icons/solid/pencil.svg";
import Chat from "../../../../../public/icons/outline/chat-bubble-oval-left-ellipsis.svg";
import Trash from "../../../../../public/icons/outline/trash.svg";
import Copy from "../../../../../public/icons/outline/document-duplicate.svg";

export default function Tooltip({ highlight, hideTooltip, interactive, baseUrl }) {
  const add = () => {
    const event = new CustomEvent("create", { detail: highlight });
    window.dispatchEvent(event);

    hideTooltip();
  };

  const remove = () => {
    const { id } = highlight;

    if (id) {
      const event = new CustomEvent("destroy", { detail: highlight });
      window.dispatchEvent(event);
    }

    hideTooltip();
  };

  const comment = () => {
    const event = new CustomEvent("comment", { detail: highlight });
    window.dispatchEvent(event);

    hideTooltip();
  };

  const copy = async () => {
    if (window.isSecureContext) {
      // The navigator.clipboard object might be undefined because the Clipboard API is only available in secure contexts (HTTPS)
      await navigator.clipboard.writeText(highlight?.content?.text)
    } else {
      console.warn('The navigator.clipboard object might be undefined because the Clipboard API is only available in secure contexts (HTTPS)');
    }

    hideTooltip();
  };

  const classNames = "flex items-center space-x-1 text-sm cursor-pointer p-1.5 rounded no-underline hover:bg-blue-100";

  const actions = [
    {
      id: 0,
      desc: I18n.actions.highlight,
      icon: <Pencil className="w-4 text-blue-900" />,
      callback: add,
    },
    {
      id: 1,
      desc: I18n.actions.comment,
      icon: <Chat className="w-4 text-blue-900" />,
      callback: comment,
    },
    {
      id: 2,
      desc: I18n.actions.remove,
      icon: <Trash className="w-4 text-blue-900" />,
      callback: remove,
    },
    {
      id: 3,
      desc: I18n.actions.copy,
      icon: <Copy className="w-4 text-blue-900" />,
      callback: copy,
    },
  ].map(({ id, desc, icon, callback }) => (
    <div key={id} className={classNames} onClick={callback}>
      {icon}
      <span>{desc}</span>
    </div>
  ));

  const link = (
    <a href={baseUrl} className={classNames}>
      <Pencil className="w-4 text-blue-900" />
      <span>{I18n.actions.fullscreen}</span>
    </a>
  );

  return <div className="flex space-x-1 border rounded shadow-xl bg-white p-0.5">{interactive ? actions : link}</div>;
}
