

import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import { MentionList } from './Mentions.jsx'

const API_DEMO = "https://restcountries.com/v3.1/all?fields=name,flags"

export default {
  items: async ({ query }) => {
    if (query.length < 1) return []

    const data = await fetch(API_DEMO).then(r => r.json())
    return data.filter((item) => item.name.common.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5).map(item => item.name.common)
  },

  render: () => {
    let reactRenderer
    let popup

    return {
      onStart: (props) => {
        if (!props.clientRect) {
          return
        }

        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
        })
      },

      onUpdate(props) {
        reactRenderer.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return reactRenderer.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        reactRenderer.destroy()
      },
    }
  },
}
