import { Controller } from "@hotwired/stimulus"

//How to use
//This controller checks if the form data has changed, in this case,
//it warns the user that will exit without saving the changes

//If the form is inside a modal, first add the controller to the container-modal

//<div
//    data-controller="modal check-form-data"
//    data-modal-active-class-value="<%= active_class %>"
//    data-modal-no-active-class-value="<%= no_active_class %>"
//    data-modal-close-url-value="<%= internal_contracts_path %>"
//    data-modal-open-url-value="<%= request.path %>"
//    data-action="handleCloseForm@window->modal#closeModal shortcutSaveModal@window->modal#submitModal shortcutEscape@window->check-form-data#handleFormHasChanges"
//  >

//Add some data-actions :
//     - handleCloseForm@window->modal#closeModal is a listener to the event which closes the modal
//     - shortcutSaveModal@window->modal#submitModal is a listener to the event which saves the form
//     - shortcutEscape@window->check-form-data#handleFormHasChanges is a listener to the event which user
//       tries to closes the modal with ESC

//Add the data-target to the form: "check-form-data-target": 'formElement'

export default class extends Controller {
  static targets = ["formElement"]
  static values = { url: String }

  initialize() {
    this.boundHandleMyEvent = this.handleFormHasChanges.bind(this);
  }

  connect(){
    //Store the URL only for the case where the user presses the go back button
    this.urlValue = window.location.href
    if(this.hasFormElementTarget) {
      this._initChangeDetection()
    }
    window.addEventListener("popstate", this.boundHandleMyEvent);
    window.addEventListener("beforeunload", this.boundHandleMyEvent);
  }

  disconnect() {
    this._removeListeners()
  }

  _initChangeDetection() {
    //TODO: select2 modifies the data after the initialization, i.e. converts 0 to 0.00€, this setTimeout waits until select2 to change the select values to check if the form data has changed correctly.
    setTimeout(() => {
      if(this.hasFormElementTarget) {
        this.initialDataFromForm = Object.fromEntries(new FormData(this.formElementTarget))
      }
    })
  }

  handleFormHasChanges(event = {}) {
    const { type, target } = event
    //If the user clicks on the save button, it is unnecessary to check if the data has been modified.
    if(target.activeElement && target.activeElement.type === 'submit' && target.activeElement.tagName === 'INPUT') {
      return;
    }
    if (this._formHasChanges()) {
      if (window.confirm(I18n.message_close_wo_save)) {
        window.dispatchEvent(new CustomEvent("handleCloseForm"));
        //Remove the eventListener because in this case, the controller doesn't fire the disconnect()
        this._removeListeners()
      } else if(type === "popstate") {
        //This event covers the case where the user presses the go back button.
        history.replaceState(null, null, this.urlValue);
        history.pushState(null, null, this.urlValue);
        return;
      } else if(type === "beforeunload") {
        //This event covers the case where the user reloads the tab or tries to close the tab.
        //It's essential to return a message to work in all browsers.
        //https://developer.mozilla.org/es/docs/Web/API/Window/beforeunload_event#ejemplos
        const confirmationMessage = I18n.message_close_wo_save;

        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      } else {
        //This ELSE covers the case where the user clicks outside, press escape, or cancel.
        event.preventDefault();
        event.stopImmediatePropagation();
        return;
      }
    } else {
      if (!target["href"]) {
        window.dispatchEvent(new CustomEvent("handleCloseForm"));
      }
      this._removeListeners()
    }
  }

  //Check if some element of the form has changed its data
  _formHasChanges() {
    if(this.hasFormElementTarget) {
      this.finalDataFromForm = Object.fromEntries(new FormData(this.formElementTarget))
      return JSON.stringify(this.finalDataFromForm) !== JSON.stringify(this.initialDataFromForm)
    }
  }

  _removeListeners() {
    window.removeEventListener("popstate", this.boundHandleMyEvent);
    window.removeEventListener("beforeunload", this.boundHandleMyEvent);
  }

}
