/**
 * Chop a large string
 * @param {String} str Original text
 * @param {Number} max Limit of displayed characters
 * @param {String} ellipsis Symbol to attach at the end
 * @returns Chopped text
 */
export const truncate = (str = "", max = 100, ellipsis = "\u2026") =>
  str.length > max ? str.substring(0, str.substring(0, max + 1).search(/\s+\S*$/)) + ellipsis : str;

/**
 * Divides an array based on a boolean clause.
 * It returns a tuple of arrays [passElements, failElements]
 *
 * @param {Array} arr Initial array
 * @param {Function} condition Boolean function to split the array based on its result
 */
export const partition = (arr = [], condition = () => {}) =>
  arr.reduce(([pass, fail], elem) => (condition(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]), [[], []]);

/**
 * Creates a random integer generator. Called it twice `random()()` to get a random integer between 0 and 100
 * @param {Integer} min minimum value
 * @param {Integer} max maximum value
 * @returns A random integer between both limits
 */
export function random(min, max) {
  return () => {
    const to = max ?? (min || 100);
    const from = !max ? 0 : min ?? 0;
    const arr = Array.from({ length: Math.floor(to - from) + 1 }, (_, i) => from + i);
    return arr[Math.floor(Math.random() * arr.length)];
  };
}

/**
 * Convenience method to generate random JSON objects
 * @returns A random length array of objects of the specified properties with random values for them
 */
export function mock() {
  const lorem =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque molestiae repellendus corporis nihil incidunt aperiam nisi explicabo voluptatum facere eveniet nobis, dolore asperiores delectus amet, aut perspiciatis quam illo quasi.".split(
      " "
    );

  return Array.from({ length: random(10, 100)() }, () => ({
    id: `${lorem[random(10)()]} ${lorem[random(10, 20)()]}`,
    date: `${random(2010, 2020)()}-${random(1, 12)().toString().padStart(2, "0")}-${random(1, 28)()
      .toString()
      .padStart(2, "0")}`,
    group: lorem[random(5)()],
    relation: lorem[random(lorem.length - 1)()],
    value: random(1e5)(),
    percentage: random(2, 98)() * 0.01,
  }));
}

/**
 * Return an tuple [x, y] where place the floating element regarding the container.
 * The values are slightly offseted from the pointer event position, and they'll be different regarding which quadrant the event was triggered in.
 * In that way, the tooltip will be always inside the container bounds.
 *
 * @param {Event} event Pointer event who triggers the calculation
 * @param {HTMLElement} element Current element to be placed (usually the tooltip itself)
 * @param {HTMLElement} container Reference parent node where the current element will be placed
 * @returns {Array} Coordinates of the point
 *
 * @example
 * const [xo, yo] = tooltipPositionOffseted(e, tooltip.node())
 * tooltip.style("top", `${yo}px`).style("left", `${xo}px`)
 */
export function tooltipPositionOffseted({clientX, clientY }, element, container = element.parentNode) {
  const OFFSET = 0.02;
  const { top, left, width: pW, height: pH } = container.getBoundingClientRect();
  const { width, height } = element.getBoundingClientRect();
  const isLeft = clientX - left < pW * 0.5;
  const isTop = clientY - top < pH * 0.5;

  return isLeft && isTop ?
    [(clientX - left) * (1 + OFFSET), (clientY - top) * (1 + OFFSET)] :
    isLeft && !isTop ?
    [(clientX - left) * (1 + OFFSET), (clientY - top - height) * (1 - OFFSET)] :
    !isLeft && isTop ?
    [(clientX - left - width) * (1 - OFFSET), (clientY - top) * (1 + OFFSET)] :
    [(clientX - left - width) * (1 - OFFSET), (clientY - top - height) * (1 - OFFSET)];
}

/**
 * Replaces an string with its slugify version
 *
 * @param {String} text
 * @returns slugged text
 */
export function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

/**
 * Debounce function makes sure that your code is only triggered once per user input
 *
 * @param {*} func The function to debounce
 * @param {*} timeout The number of milliseconds to dela
 * @returns Returns the new debounced function.
 */
export function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
