import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect(){
    document.addEventListener("turbo:load", () => this.initVizzsFromEvent())
  }

  initVizzsFromEvent() {
    const element = document.querySelector(".js-control-summary");
    if (element && !element.classList.contains("hidden")) {
      window.dispatchEvent(new CustomEvent("initVizzsFromEvent"));
    }
  }
}
