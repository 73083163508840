import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["inputElement", "form"]

  static values = {
    availableIds: Array,
    selectedIds: Array,
    table: String
  };

  connect() {
    this._updateCellsVisibility();
  }

  changeColumn(event) {
    this.selectedIdsValue = [...this.inputElementTargets]
      .reduce((total, { id, checked }) => checked ? [...total, id] : total, []);

    this._updateCellsVisibility();
    this.storeColumns(event);
  }

  storeColumns(event) {
    /* If no column is selected, the form isn't submitted, in order to avoid reloading the table */
    if(this.selectedIdsValue.length > 1) {
      Rails.fire(this.formTarget, 'submit');
    }
  }

  // private

  _updateCellsVisibility(){
    const visibleArray = this.selectedIdsValue;
    const hideableArray = this.availableIdsValue.filter((element) => this.selectedIdsValue.indexOf(element) < 0);

    const tables = document.querySelectorAll(`table[data-toggle-table-id="${this.tableValue}"]`);

    tables.forEach((table) =>{
      let tableHeight = 0;
      hideableArray.forEach((column) => {
        table.querySelector(`th[data-toggle-column-id="${column}"]`).classList.add("hidden")
        table.querySelectorAll(`td[data-toggle-column-id="${column}"]`).forEach((tdElement) => tdElement.classList.add("hidden"))
      });

      visibleArray.forEach((column) => {
        if (table.querySelector(`th[data-toggle-column-id="${column}"]`)) {
          table.querySelector(`th[data-toggle-column-id="${column}"]`).classList.remove("hidden")
        }
        if (table.querySelectorAll(`td[data-toggle-column-id="${column}"]`).length > 0) {
          table.querySelectorAll(`td[data-toggle-column-id="${column}"]`).forEach((tdElement) => tdElement.classList.remove("hidden"))
        }
      });

      //Calculate the table total height by adding up each TR height.
      table.querySelectorAll("tr[data-table-manager-target='trElement']").forEach((tr) => tableHeight += tr.offsetHeight)

      const thead = table.querySelector('thead');
      let theadHeight = 0
      if(thead) {
        theadHeight = thead.clientHeight;
      }

      //Update the height of all resizer bars when columns are updated
      table.querySelectorAll('.table-resizer').forEach((tableResizer) => {
        tableResizer.style.height = `${tableHeight - theadHeight}px`;
      })

      table.classList.remove("invisible")
    });

  }

}
