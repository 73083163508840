import { Controller } from "@hotwired/stimulus";
import AutoNumeric from 'autonumeric';

export default class extends Controller {
  static targets = [
    "totalAmountField", "annualitiesContainer", "annualityInfo",
    "totalAmountTd", "template", "enablerCheckbox"
    ];

  connect(){
    if (this.hasEnablerCheckboxTarget) {
      if (this.enablerCheckboxTarget.checked){
        this._toggleChecked(this.enablerCheckboxTarget);
      } else {
        this._toggleUnchecked(this.enablerCheckboxTarget);
      }

      this._updateAllValues(false)
    }
  }

  toggle(event){
    if (event.target.checked) {
      this._toggleChecked(event.target);
    } else {
      this._toggleUnchecked(event.target);
    }
  }

  updateTotal(event){
    if (event && event.target.closest("tr") == this.annualityInfoTargets[this.annualityInfoTargets.length-1]) {
      const year = parseInt(event.target.closest("tr").querySelector(".js-year").innerHTML)+1;
      this._appendNewYear(year);
    }

    this._updateAllValues(true)
  }

  // the total amount for all batches is outside the scope of this controller, so we launch an event
  // so the batches controller is aware and updates the right value. See project_batches_controller#updateTotal
  notifyChange(){
    window.dispatchEvent(new Event("update-batches-total")) // updates all batches total
  }

  // private

  _toggleChecked(checkboxElement){
    checkboxElement.parentNode.classList.add("w-full");
    this.totalAmountFieldTarget.classList.add("hidden");
    this.annualitiesContainerTarget.classList.remove("hidden");
    this._updateAllValues(false)
  }

  _toggleUnchecked(checkboxElement){
    checkboxElement.parentNode.classList.remove("w-full");
    this.totalAmountFieldTarget.classList.remove("hidden");
    this.annualitiesContainerTarget.classList.add("hidden");
  }

  _appendNewYear(year){
    const content = this.templateTarget.innerHTML.replace(/YEAR_INFO/g, year)
    let lastTr = this.annualityInfoTargets[this.annualityInfoTargets.length-1];
    lastTr.insertAdjacentHTML('afterend', content)
  }

  _updateAllValues(notifyChange){
    const sum = this._getSum()

    const formattedSum = AutoNumeric.format(sum, {
      currencySymbol: ' €',
      currencySymbolPlacement: 's',
      decimalCharacter: ',',
      digitGroupSeparator: '.',
      unformatOnSubmit: true
    });

    this.totalAmountTdTarget.innerHTML = formattedSum; // Total field
    AutoNumeric.set(this.totalAmountFieldTarget.querySelector("input"), sum); // current batch total
    if (notifyChange) { this.notifyChange(); }

    // Percentages
    this.annualityInfoTargets.forEach(target => {
      const amountFieldId = target.querySelector(".js-amount").id
      const amountField = document.querySelector(`#${amountFieldId}`);
      const amount = this._getAmountFromAmountField(amountField);

      const percentage = ((amount/sum)*100)
      const finalPercentage = Number.isNaN(percentage) ? 0 : percentage.toFixed(2)

      target.querySelector(".js-percentage").innerHTML = `${finalPercentage}%`;
    })
  }

  _getAmountFromAmountField(field){
    let amount;

    // When a new year is added, Autonumeric won't find it instantly when typing and it raises an error.
    // On the second type it does not raise an error. So this is just for the first type each time a new field is added.
    try {
      if (AutoNumeric.isManagedByAutoNumeric(field)) {
        amount = AutoNumeric.getNumber(field);
      } else {
        amount = parseFloat(AutoNumeric.unformat(field.value));
      }
    } catch (error) { amount = 0; }

    return amount
  }

  _getSum(){
    let sum = 0;
    this.annualityInfoTargets.forEach(target => {
      const amountFieldId = target.querySelector(".js-amount").id
      const amountField = document.querySelector(`#${amountFieldId}`);
      const amount = this._getAmountFromAmountField(amountField);

      sum += amount
    })
    return sum;
  }
}
