import { select, pointer } from "d3-selection";
import { scaleLinear, scaleTime, scaleOrdinal } from "d3-scale";
import { extent, group, least } from "d3-array";
import { axisBottom } from "d3-axis";
import { timeMonth } from "d3-time";
import { line, curveMonotoneX as curve } from "d3-shape";
import { tooltipPositionOffseted } from "../helpers"

export default class Linechart {
  constructor(container, data, options = {}) {
    this.container = container;
    this.margin = { top: 0, bottom: 20, left: 0, right: 0, ...options.margin };

    // main properties to display
    this.xAxisProp = options.x || "x";
    this.yAxisProp = options.y || ["y"];

    this.PALETTE = options.palette || Array.from({ length: 10 }, (_, i) => `var(--gv-color-${i + 1})`)
    this.lineWidth = options.lineWidth
    this.tooltip = options.tooltip || this.defaultTooltip
    this.tooltipYLabels = options.tooltipYLabels || this.yAxisProp

    // grouper key column (offuscated name to avoid overwrite dataset columns)
    const seed = Math.random().toString(36).substring(7)
    this.groupKey = `group-${seed}`
    this.valueKey = `value-${seed}`

    // chart size
    this.getDimensions();
    // static elements (do not redraw)
    this.setupElements();

    if (data.length) {
      this.setData(data);
    }

    window.addEventListener("resize", this.resizeListener.bind(this));
  }

  getDimensions() {
    const { width, height } = this.container.getBoundingClientRect();
    this.width = width - this.margin.left - this.margin.right;
    this.height = height ? height - this.margin.top - this.margin.bottom : this.width / (4 / 1);
  }

  setupElements() {
    this.svg = select(this.container)
      .attr("class", "relative")
      .on("pointerout", this.onPointerOut.bind(this))
      .on("pointermove", this.onPointerMove.bind(this))
      .append("svg");

    this.g = this.svg
      .append("g")
      .attr("transform", `translate(${this.margin.left} ${this.margin.top})`)

    this.g.append("g").attr("class", "axis axis-x");
    this.currentPoint = this.g.append("circle").attr("r", 3).attr("display", "none");

    this.tooltipContainer = select(this.container)
      .append("div")
      .attr("class", "shadow rounded p-4 absolute bg-white z-10 opacity-0 text-sm");
  }

  build() {
    this.setScales();

    this.svg.attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + this.margin.top + this.margin.bottom}`
    );

    this.g.select(".axis-x")
      .attr("transform", `translate(0 ${this.height})`)
      .call(this.xAxis.bind(this));

    this.g
      .selectAll("path.path")
      .data(group(this.data, d => d[this.groupKey]))
      .join("path")
      .attr("d", ([, data]) =>
        line()
          .x((d) => this.scaleX(d[this.xAxisProp]))
          .y((d) => this.scaleY(d[this.valueKey]))
          .curve(curve)
          (data)
      )
      .attr("fill", "none")
      .attr("stroke", ([key]) => this.scaleColor(key))
      .attr("stroke-width", this.lineWidth || Math.max(Math.min(this.height / 100, 2), 1))
      .attr("class", "path")
  }

  setData(data) {
    this.rawData = data;
    this.data = this.parse(data);

    this.build();
  }

  parse(data) {
    // 1. remove those elements with no X axis data
    // 2. enforces the datatypes:
    //    - X axis is Date
    //    - Y axis properties are Numbers
    const reducer = (prop) =>
      data.reduce(
        (acc, d) => [
          ...acc,
          ...(!!d[this.xAxisProp] && !!d[prop]
            ? [
                {
                  ...d,
                  [this.xAxisProp]: new Date(d[this.xAxisProp]),
                  [this.groupKey]: prop,
                  [this.valueKey]: +d[prop],
                },
              ]
            : []),
        ],
        []
      );
    return this.yAxisProp
      .reduce((acc, y) => [...acc, ...reducer(y)], [])
      .sort((a, b) => (a[this.xAxisProp] > b[this.xAxisProp] ? 1 : -1));
  }

  setScales() {
    this.scaleX = scaleTime()
      .domain(extent(this.data, (d) => d[this.xAxisProp]))
      .range([0, this.width])

    this.scaleY = scaleLinear().domain(extent(this.data, d => d[this.valueKey])).range([this.height, 0]).nice();

    this.scaleColor = scaleOrdinal().domain(this.yAxisProp).range(this.PALETTE)
  }

  xAxis(g) {
    const months = timeMonth.count(...this.scaleX.domain())
    const MONTHS_LIMIT = 36
    const [MIN_TICKS, MAX_TICKS] = [2, 10]
    const [ESTIMATED_MONTH_LENGTH, ESTIMATED_YEAR_LENGTH] = [50, 200]

    g.call(
      axisBottom(this.scaleX)
        .tickSize(0)
        .tickPadding(6)
        .ticks(
          Math.max(
            MIN_TICKS,
            Math.min(
              MAX_TICKS,
              months > MONTHS_LIMIT ? this.width / ESTIMATED_MONTH_LENGTH : this.width / ESTIMATED_YEAR_LENGTH
            )
          )
        )
    );

    // remove those ticks at the edge, if they get cut off
    const { x: parentX } = this.container.getBoundingClientRect()
    g.selectAll(".tick")
      .filter((_, i, nodes) => {
        const { x, width } = nodes[i].getBoundingClientRect();
        return x - parentX + this.margin.left - width < 0 || x - parentX - this.margin.right + width > this.width;
      })
      .remove();

    g.select(".domain").attr("class", "text-black text-opacity-25");
    g.attr("font-family", null)
      .attr("class", "text-black text-opacity-50")
      // axis font-size based on the chart width, between [0.5em, 1em]
      .attr("font-size", `${Math.max(Math.min(this.width / 150 - 1, 1), 0.5)}em`);
  }

  onPointerMove(e) {
    const [xm, ym] = pointer(e);

    // Get the minor distance between the mouse position and the data points
    const closestPoint = least(this.data, (d) => Math.hypot(this.scaleX(d[this.xAxisProp]) - xm, this.scaleY(d[this.valueKey]) - ym));

    this.currentPoint
      .attr("display", null)
      .attr("transform", `translate(${this.scaleX(closestPoint[this.xAxisProp])} ${this.scaleY(closestPoint[this.valueKey])})`)
      .raise();

    const tooltip = this.tooltipContainer.html(this.tooltip(closestPoint));
    const [xo, yo] = tooltipPositionOffseted(e, tooltip.node())

    tooltip
      .style("top", `${yo}px`)
      .style("left", `${xo}px`)
      .style("width", "max-content")
      .style("opacity", 1)
  }

  onPointerOut() {
    this.currentPoint.attr("display", "none")
    this.tooltipContainer.style("opacity", 0);
  }

  defaultTooltip(d) {
    return [
      `<div>${d[this.xAxisProp].toLocaleDateString()}</div>`,
      this.yAxisProp.map((y, i) => `<div>${this.tooltipYLabels[i]}: ${d[y]}</div>`).join(""),
    ].filter(Boolean).join("");
  }

  resizeListener() {
    this.getDimensions();
    this.build();
  }
}
