import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "checkbox"]

  updateMarkers(){
    let tagCounters = {}

    // we first count the number of each tag id
    this.checkboxTargets.filter((checkbox) => checkbox.checked).forEach(function(checkbox){
      const tagIds = checkbox.dataset.tagIds.split(',')
      tagIds.forEach(function(tagId){
        if (tagCounters[tagId] === undefined) {
          tagCounters[tagId] = 1
        } else {
          tagCounters[tagId] += 1
        }
      })
    })

    const fullSize = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;

    // Updating the markers in each possible tag option from the dropdown
    this.optionTargets.forEach((option) => {
      const currentTagId = option.dataset.assignValue;
      if (tagCounters[currentTagId] === fullSize) {
        option.dataset.assignAttribute = 'remove_tag'
        this._setMarker(option, 'js-all');
      } else if (tagCounters[currentTagId] !== undefined) {
        option.dataset.assignAttribute = 'tag_id'
        this._setMarker(option, 'js-some');
      } else {
        option.querySelectorAll("span.js-marker").forEach(elem => {elem.classList.add('hidden');});
        option.dataset.assignAttribute = 'tag_id'
      }
    })
  }

  // private

  _setMarker(option, value){
    option.querySelectorAll("span.js-marker").forEach(elem => {elem.classList.add('hidden');});
    option.querySelector(`span.${value}`).classList.remove('hidden');
  }

}
