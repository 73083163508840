import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "selectField" ]
  static values = {
    avoidCache: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    const { filter, url, searchAttributeName } = this.selectFieldTarget.dataset;
    const MINIMUM_INPUT_LENGTH = 3

    const tsSelect = new TomSelect(this.selectFieldTarget, {
      plugins: ["clear_button"],
      maxItems: (this.selectFieldTarget.getAttribute("multiple") === "multiple" ? null : 1),
      valueField: "id",
      sortField:[{field:'$order'},{field:'$score'}],
      render: {
        no_results: () => `<div class="px-2">${I18n.no_results}</div>`,
        loading: () => `<div class="px-2">${I18n.searching}&hellip;</div>`,
        not_loading: () => `<div class="px-2">${I18n.input_short}</div>`,
      },
      shouldLoad: query => query.length > MINIMUM_INPUT_LENGTH,
      load: (search, callback) => {
        let params;
        if (this.avoidCacheValue) {
          tsSelect.clearOptions()
        }

        if (searchAttributeName) {
          params = new URLSearchParams([[searchAttributeName, search], ["filter", filter]]);
        } else {
          params = new URLSearchParams({ search, filter });
        }
        const separator = url.includes("?") ? "&" : "?"
        fetch(url + separator + params.toString(), {
          headers: new Headers({
            "Accept": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((json) => callback(json))
          .catch(() => callback());
      }
    })
  }
}
