import { Controller } from "@hotwired/stimulus";
import '@github/text-expander-element';
import Rails from "@rails/ujs"


export default class extends Controller {
  static targets = ["options", "buttonComment", "formComment"]

  connect() {
    this._bodyElement.classList.add("form-mentionable")
    if (this.hasButtonCommentTarget) {
      this.buttonCommentTarget.addEventListener('click', () => this._changeTextButton());
    }
  }

  disconnect() {
    this._bodyElement.classList.remove("form-mentionable")
  }

  showOptions(event){
    const {key, provide, text} = event.detail
    if (key !== '@') return

    const normalizedText = this._normalize(text);

    const suggestions = this.optionsTarget.cloneNode(true);
    suggestions.classList.remove("hidden");


    for (const suggestion of suggestions.children) {
      const normalizedSuggestion = this._normalize(suggestion.dataset.value)

      if (!normalizedSuggestion.includes(normalizedText)) {
        suggestion.classList.add("hidden")
      }
    }

    provide(Promise.resolve({matched: suggestions.childElementCount > 0, fragment: suggestions}))
  }

  selectOption(event){
    const {key, item}  = event.detail

    if (key === '@') {
      event.detail.value = `@${item.getAttribute('data-value')}`
    }
  }

  postComment() {
    this._changeTextButton()
    Rails.fire(this.formCommentTarget, 'submit');
  }

  // private
  _normalize(string){
    return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  _changeTextButton() {
    this.buttonCommentTarget.setAttribute('value', I18n.actions.saving_comment);
    setTimeout(() => {
      this.buttonCommentTarget.setAttribute('value', I18n.actions.comment);
    }, 1000)
  }

  get _bodyElement() {
    return document.body;
  }

}
