import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['form']
  static values = {editMode: Boolean}

  connect(){
  }

  maybeSubmit(event){
    if (this.editModeValue && document.activeElement.nodeName !== "INPUT"){
      Rails.fire(this.formTarget, 'submit');
    }
  }

  enableEditMode(event){
    if (this.editModeValue === false && event.detail.formId === this.formTarget["id"]) {
      this.editModeValue = true;
    }
  }

}
