import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["visibleElement"]
  static values = {
    turboLoadUrl: String
  }

  connect() {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this._turboRequest()
      }
    })
    observer.observe(this.visibleElementTarget)
  }

  _turboRequest(){
    fetch(this.turboLoadUrlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

}
