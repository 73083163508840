import { Controller } from "@hotwired/stimulus"

// - This controller toggles the "active" class
//   in a group of links once one is selected.
// - It does not have further display/hide functionality.
// - This is best paired with links that show content in a turbo frame.

export default class extends Controller {
  static targets = ['link']

  connect(){
  }

  setCurrent(event){
    this.linkTargets.forEach(function(target){
      target.classList.remove("active");
    });

    event.target.classList.add("active");
  }

}
