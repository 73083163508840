import { Controller } from "@hotwired/stimulus"
import posthog from 'posthog-js'
import 'vanilla-cookieconsent/dist/cookieconsent.js'

export default class extends Controller {
  static values = {
    apiKey: String
  }
  initialize() {
    this._posthog = this.startPosthog.bind(this);
  }
  connect() {
    window.addEventListener("vanilla-cookieconsent--changed", this._posthog)
  }
  disconnect() {
    window.removeEventListener("vanilla-cookieconsent--changed", this._posthog)
  }
  startPosthog() {
    const cc = initCookieConsent();

    // If user authorized analytics cookies
    if(this.apiKeyValue !== undefined && this.apiKeyValue.length && cc.allowedCategory('analytics')) {
      posthog.init(this.apiKeyValue, { api_host: 'https://eu.posthog.com' })

      if (window.userServices.id.length) {
        posthog.identify(
          window.userServices.id,
          { email: window.userServices.email }
        )
        posthog.group('company', 'id: ' + window.userServices.companyId, {
          name: window.userServices.company
        })
      } else {
        // Check if the URL includes the parameter session_logout to reset the session
        const url = new URL(window.location.href)
        if (url.searchParams.get('session_logout') !== null) {
          posthog.reset()
        }
      }
    }
  }
}
