import { Controller } from "@hotwired/stimulus";

/**
* This controller is used to provide feedback about the assistant answer.
* @markup-example:

<div data-controller="assistant-feedback">
  <p class="font-bold mb-2">Ayúdanos a mejorar: ¿Qué tal la respuesta?</p>
  <button
          data-action="click->assistant-feedback#rate"
          data-assistant-feedback-target="rating"
          data-base-text="¡Gracias por tu feedback!"
          data-rating="Bien">
    Bien
  </button>
</div>
**/

export default class extends Controller {

  static targets = ["rating"]

  rate(event) {
    const element = this.ratingTarget
    const baseText = element.dataset.baseText
    const rating = event.target.dataset.rating

    const text = `La respuesta me ha parecido: ${rating}\n\nSi quieres, añade detalle de por qué te parece. Si no, simplemente envía esto y lo revisamos ;)\n\n${baseText}`

    // Clear previous state
    // window.$crisp.do("session:reset");

    // Set the message and then open the chat, otherwise the message is not set
    window.$crisp.do('chat:close');
    window.$crisp.push(["do", "chat:open"])
    window.$crisp.push(["set", "message:text", [text]])
  }
}
