import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'

function handleBlur({ key, value, prop }) {
  const props = JSON.parse(localStorage.getItem(prop))

  // TODO: for the time being, localStorage is used. Later on, it must be fetch/persisted in a database
  localStorage.setItem(prop, JSON.stringify({ ...props, [key]: value }))
}

function mockDB({ type, key }) {
  const obj = {
    "project-data": {
      "uno": "Valor mockeado obtenido de project-data[uno]",
      "dos": "Valor mockeado obtenido de project-data[dos]"
    }
  }

  return obj?.[type]?.[key]
}

export default (props) => {
  const { id, ...params } = props.extension.options.HTMLAttributes
  const { key, type, contenteditable } = props.node.attrs

  const prop = `${id}-props`

  // TODO: for the time being, localStorage is used. Later on, it must be fetch/persisted in a database
  const text = JSON.parse(localStorage.getItem(prop))?.[key] || mockDB({ type, key })

  const onBlur = ({ target }) => handleBlur({ key, value: target.innerHTML, prop })

  return <NodeViewWrapper as="span" placeholder={key} onBlur={onBlur} contentEditable={contenteditable}>{text}</NodeViewWrapper>
}
