import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectField", "companyRadio", "publicAdministrationRadio" ]

  connect(){
  }

  updateRadios(){
    const selectValue = this.selectFieldTarget.value
    if (selectValue.startsWith("private_entity")) {
      this.companyRadioTarget.checked = true
      this.publicAdministrationRadioTarget.checked = false
    } else if (selectValue.startsWith("public_entity")) {
      this.publicAdministrationRadioTarget.checked = true
      this.companyRadioTarget.checked = false
    }
  }

}
