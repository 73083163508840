import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newBidToggleContainer", "newBidButtonContainer"]

  toggleNewBid(event){
    this.newBidToggleContainerTarget.classList.toggle('hidden');
    this.newBidButtonContainerTarget.classList.toggle('hidden');
  }

  toggleEditBid(event){
    const showContainer = event.currentTarget
    const editContainerId = showContainer.id.replace("show", "edit")
    const editContainer = document.querySelector(`#${editContainerId}`)

    showContainer.classList.add("hidden")
    editContainer.classList.remove("hidden")
  }
}
