import React, { useEffect, useState } from "react";
import { AreaHighlight, Highlight, PdfHighlighter as OriginalPdfHighlighter, PdfLoader, Popup } from "react-pdf-highlighter";
import { PDFFindController, PDFLinkService, PDFViewer, EventBus } from "pdfjs-dist/legacy/web/pdf_viewer.mjs";
import "./PdfHighlighter.css";
import "react-pdf-highlighter/dist/style.css";
import Searcher from "./components/Searcher";
import Tooltip from "./components/Tooltip";

// function will be properly initalizated on the scrollRef prop
let handleScroll = () => {};

// extends the original component, overwritting the init method,
// in order to include the modification: be able to search through all the document pages
//
// original issue: https://github.com/PopulateTools/gobierto-contratos/issues/4363
export class PdfHighlighter extends OriginalPdfHighlighter {
  init() {
    const { pdfDocument, finders } = this.props;
    this.eventBus = new EventBus();
    this.linkService = new PDFLinkService({
      eventBus: this.eventBus,
      externalLinkTarget: 2,
    });
    if (!this.containerNodeRef.current) {
      throw new Error("!");
    }
    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNodeRef.current,
        eventBus: this.eventBus,
        // enhanceTextSelection: true, // deprecated. https://github.com/mozilla/pdf.js/issues/9943#issuecomment-409369485
        textLayerMode: 2,
        removePageBorders: true,
        linkService: this.linkService,
        findController: new PDFFindController({
          eventBus: this.eventBus,
          linkService: this.linkService,
        }),
      });
    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);

    finders(this.goToPreviousMatch, this.goToNextMatch);
    this.eventBus.on("updatetextlayermatches", this.calculateMatchProgress);

    this.viewer.setDocument(pdfDocument);
    this.attachRef(this.eventBus);

    /**
     * The default PdfHighlighter.tsx render method contain the following statement: "onContextMenu={(e) => e.preventDefault()}"
     * what disables the context menu to be shown. Render methods cannot be overwritten in React,
     * so this hack disables the disabled event 🤯
     *
     * See source code: https://github.com/agentcooper/react-pdf-highlighter/blob/4a65c282a76915d4ff3e53d11be15b8b56d50ffe/src/components/PdfHighlighter.tsx#L565
     * See solution: https://gist.github.com/vandanojan/5a94c3e601826f2e6d6bc8c8aaa1d5e9
     */
    this.containerNodeRef.current.oncontextmenu = function (event) {
      typeof event.stopPropagation === "function" && event.stopPropagation();
    };
  }

  unsubscribe() {
    super.unsubscribe()
    this.eventBus.off("updatetextlayermatches", this.calculateMatchProgress);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.searchValue !== this.props.searchValue) {
      this.eventBus.dispatch("find", {
        query: this.props.searchValue,
        highlightAll: true,
        phraseSearch: true,
        caseSensitive: false
      });
    }
  }

  calculateMatchProgress = () => {
    let totalMatchCount = 0;
    let currentMatchIndex = 0;
    let hasCurrentMatchIndex = false;
    this.viewer.findController.pageMatches.forEach(
      (pageMatches, pageIndex) => {
        if (
          !hasCurrentMatchIndex &&
          pageIndex === this.viewer.findController.selected.pageIdx
        ) {
          currentMatchIndex =
            totalMatchCount + this.viewer.findController.selected.matchIdx;
          hasCurrentMatchIndex = true;
        }
        totalMatchCount += pageMatches.length;
      }
    );
    const currentMatch = totalMatchCount === 0 ? 0 : currentMatchIndex + 1;
    this.props.onSearch(currentMatch, totalMatchCount);
  };

  goToNextMatch = () => {
    this.eventBus.dispatch("find", {
      query: this.props.searchValue,
      highlightAll: true,
      type: "again"
    });
  };

  goToPreviousMatch = () => {
    this.eventBus.dispatch("find", {
      query: this.props.searchValue,
      highlightAll: true,
      findPrevious: true,
      type: "again"
    });
  };

  handleKeyDown = async (event) => {
    // original code
    if (event.code === "Escape") {
      this.hideTipAndSelection();
    }

    // appended code
    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      if (window.isSecureContext) {
        // The navigator.clipboard object might be undefined because the Clipboard API is only available in secure contexts (HTTPS)
        await navigator.clipboard.writeText(window.getSelection().toString())
      } else {
        console.warn('The navigator.clipboard object might be undefined because the Clipboard API is only available in secure contexts (HTTPS)');
      }
    }
  };
}

export default function App({ url, annotations, ...props }) {
  const [searchValue, setSearchValue] = useState("")
  const [finders, setFinders] = useState({})
  const [currentMatch, setCurrentMatch] = useState(0)
  const [totalMatchCount, setTotalMatchCount] = useState(0)

  const onPushState = () => {
    const { groups: { annotationId } = {} } = location.pathname.match(/annotations\/(?<annotationId>[^/]*)/) || {};

    if (annotationId) {
      const highlight = annotations.find(({ id }) => +id === +annotationId);

      if (highlight) {
        handleScroll(highlight);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("push-state", onPushState);
    return () => {
      window.removeEventListener("push-state", onPushState);
    };
  }, [annotations]); // reset the effect when annotations change

  return (
    <PdfLoader url={url} beforeLoad={<div />}>
      {(pdfDocument) => (
        <>
          <Searcher
            setSearchValue={(searchValue) => setSearchValue(searchValue)}
            finders={finders}
            currentMatch={currentMatch}
            totalMatchCount={totalMatchCount}
          />
          <PdfHighlighter
            searchValue={searchValue}
            finders={(prev, next) => setFinders({ prev, next })}
            onSearch={(currentMatch, totalMatchCount) => {
              setCurrentMatch(currentMatch)
              setTotalMatchCount(totalMatchCount)
            }}
            pdfDocument={pdfDocument}
            pdfScaleValue={1}
            enableAreaSelection={(event) => event.altKey}
            onScrollChange={() => {}}
            scrollRef={(scrollTo) => {
              handleScroll = scrollTo;
              // Call to this function once handleScroll has been defined
              onPushState();
            }}
            onSelectionFinished={(position, content, hideTip) => (
              <Tooltip highlight={{ position, content }} hideTooltip={hideTip} {...props} />
            )}
            highlightTransform={(highlight, index, setTip, hideTip, viewportToScaled, screenshot, isScrolledTo) => {
              const isTextHighlight = !Boolean(highlight.content && highlight.content.image);

              const component = isTextHighlight ? (
                <Highlight isScrolledTo={isScrolledTo} position={highlight.position} comment={highlight.comment} />
              ) : (
                <AreaHighlight highlight={highlight} />
              );

              return (
                <Popup
                  key={index}
                  popupContent={<Tooltip highlight={highlight} hideTooltip={hideTip} {...props} />}
                  onMouseOver={(popupContent) => setTip(highlight, () => popupContent)}
                  onMouseOut={hideTip}
                  children={component}
                />
              );
            }}
            highlights={annotations}
          />
        </>
      )}
    </PdfLoader>
  );
}
