import { Controller } from "@hotwired/stimulus";

/*
- This controller was designed to work with duration_adder_component.rb ViewComponent
- Since it was not designed to work outside that component, it does not rely on targets
   as much as other controllers, hence the need to pass the CssSelectors for the fields.

data attributes:

- data-original-value (optional): In both origin and target field, this is the current value of the field.
  If not passed, the controller will use the existing value of the field. If empty, it'll use the current date.

targets:

- customInputValue: It's already defined in the ViewComponent. It's the number of units (in "8 days", it's the 8)
- customInputMultiplied: It's already defined in the ViewComponent. It's the multiplier of
  the unit (in "8 months", it's 30 because a month is 30 days)

values:

- targetFieldCssSelector: the css selector (".class" or "#id") of the field whose date field will be modified.
- originFieldCssSelector (optional): the css selector (".class" or "#id") of the field will based the start date upon.
  If not provided, the target will also be the origin.

*/

export default class extends Controller {
  static targets = [ "container", "customInputValue", "customInputMultiplier" ]

  static values = {
    targetFieldCssSelector: String,
    originFieldCssSelector: { type: String, default: '' }
  }

  // Predefined buttons (1 mes, 1 año, etc) uses this.
  add(event){
    event.preventDefault();

    const durationDays = event.target.dataset.durationDays;
    this._calculateAndSetDateInTarget(durationDays);

    this._closeDropdown();
  }

  // Custom field inputs (value and multiplier targets) use this.
  addCustom(event){
    event.preventDefault();

    const customInputValue = this.customInputValueTarget.value;
    const customInputMultiplier = this.customInputMultiplierTarget.value;

    if (customInputValue !== '' && customInputMultiplier !== '' ) {
      const durationDays = parseInt(customInputValue) * parseInt(customInputMultiplier);
      this._calculateAndSetDateInTarget(durationDays);
    }

    this._closeDropdown();
  }

  // private

  _calculateAndSetDateInTarget(durationDays){
    const date = this._getStartDate();

    date.setDate(date.getDate() + parseInt(durationDays));
    this._targetField().valueAsDate = date;
    this._targetField().dispatchEvent(new Event('change'));
  }

  // We save the initial value because otherwise it'd keep adding values.
  _getStartDate(){
    const field = this._getCurrentOriginField();

    if (field.dataset.originalValue){
      return new Date(field.dataset.originalValue);
    } else {
      const today = new Date();
      const originalValue = field.value ? field.value : `${today.getUTCFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
      field.dataset.originalValue = originalValue;
      return new Date(field.dataset.originalValue);
    }
  }

  _getCurrentOriginField(){
    if (this._originField()) {
      return this._originField()
    } else if(this._targetField()){
      return this._targetField()
    }
  }

  _targetField(){
    return document.querySelector(this.targetFieldCssSelectorValue);
  }

  _originField(){
    if (this.originFieldCssSelectorValue !== '') {
      return document.querySelector(this.originFieldCssSelectorValue);
    } else {
      return null;
    }
  }

  _closeDropdown(){
    this.containerTarget.querySelector("button[data-dropdown-menu-target='toggleButton']").click();
  }

}
