import { Controller } from "@hotwired/stimulus"

/*
  This controller is used to manage the status of the user feedback thumbs
  in the Assistant answers.

  How to use: /app/views/message_answers/_feedback_buttons.html.erb
 */
export default class extends Controller {
  static targets = ["thumb"]

  handleClick(event) {
    const element = event.currentTarget;
    const value = element.getAttribute("data-value");

    this._clearDataFeedback();

    element.setAttribute("data-feedback", value);
    element.setAttribute("disabled", "disabled");
  }

  // private

  _clearDataFeedback() {
    this.thumbTargets.forEach((element) => {
      element.removeAttribute("data-feedback");
    });
  }
}
