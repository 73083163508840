import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "selectField", "formField" ]
  static values = { existingOptions: Array, currentItem: String }

  connect() {
    new TomSelect(this.selectFieldTarget, {
      valueField: "value",
      options: this.existingOptionsValue.map((option) => ({ value: option, text: option })),
      items: [this.currentItemValue],
      create: true,
      openOnFocus: false,
      maxItems: 1,
      render: {
        option_create: function(data, escape) {
          return '<div class="create">Preguntar <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results:function(data,escape){
          return '<div class="no-results"></div>';
        },
      },
      onItemAdd: (value) => {
        this.selectFieldTarget.value = value;
        this.formFieldTarget.querySelector("button").click();
      }
    })
  }
}
