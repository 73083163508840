 import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form"]

  connect(){
    this._hideSpinner();
  }

  prepareSorting(){
    this._showSpinner();
  }

  // private

  _hideSpinner(){
    const spinner = document.querySelector(".js-spinner-search");
    if (spinner) {
      spinner.classList.add('hidden');
    }
  }

  _showSpinner(){
    const spinner = document.querySelector(".js-spinner-search");
    if (spinner) {
      spinner.classList.remove('hidden');
    }
  }

}
