import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalOpenButton", "modalCloseButton", "modalContainer", "modalContent", "modalForm", "modalEdit"]
  static values = {
    activeClass: String,
    noActiveClass: String,
    closeUrl: String,
    openUrl: String,
    openOnConnect: Boolean,
    openEventName: String
  }

  connect(){
    if (this.openOnConnectValue) {
      this.openModal();
    }
  }

  disconnect() {
    this._bodyElement.classList.remove('form-modal')
  }

  closeModal(event) {
    const currentContainer = this.modalContainerTargets.find( element =>
      element.classList.contains(this._activeClass())
    )

    const currentContent = this.modalContentTargets.find( element =>
      element.classList.contains(this._activeClass())
    )

    currentContent?.classList.add(this._noActiveClass())
    setTimeout(() => {
      currentContainer?.classList.add(this._noActiveClass())
      setTimeout(() => {
        currentContainer?.classList.remove(this._activeClass())
        currentContent?.classList.remove(this._activeClass())
      }, 150)
    }, 150)
    this._bodyElement.classList.remove('overflow-y-hidden', 'sticky', 'form-modal')
    window.scrollTo({
      top: this.scrollPosition,
      behavior: 'smooth'
    });
    this._bodyElement.style.top = 0;
    if (this.hasOpenUrlValue && this.hasCloseUrlValue){
      this._maybeUpdateUrl(this.closeUrlValue)
    }

    const displayOnCloseElement = document.querySelector("[data-display-on-close-modal]")
    if (displayOnCloseElement) {
      displayOnCloseElement.style.display='';
    }
  }

  openModal(){
    this.scrollPosition = window.pageYOffset;
    this.modalContainerTarget.classList.remove(this._noActiveClass())
    this.modalContainerTarget.classList.add(this._activeClass())
    this.modalContentTarget.classList.add(this._activeClass())
    this.modalContentTarget.classList.remove(this._noActiveClass())

    this._bodyElement.classList.add('overflow-y-hidden', 'sticky', 'form-modal')
    //Gets the positionY to avoid scrolling when opening the modal
    this._bodyElement.style.top = `${-this.scrollPosition}px`;

    if (this.hasOpenUrlValue && this.hasCloseUrlValue){
      this._maybeUpdateUrl(this.openUrlValue)
    }
  }

  // Check modal_opener_controller to see how to open a modal through a link outside this modal controller scope
  openModalThroughEvent(event){
    if (this.hasOpenEventNameValue && this.openEventNameValue === event.detail.openEventName) {
      this.openModal()
    }
  }

  // It updates the url in the browser
  // If it's already the same url, it does nothing.
  _maybeUpdateUrl(newUrl){
    if (!window.location.href.endsWith(newUrl)){
      Turbo.navigator.history.push(newUrl);
      history.pushState(history.state, null, newUrl)
    }
  }

  submitModal() {
    if(this.hasModalFormTarget) {
      this.modalFormTarget.requestSubmit()
    }
  }

  _activeClass(){
    return this.activeClassValue || 'active-animated'
  }

  _noActiveClass(){
    return this.noActiveClassValue || 'no-active-animated'
  }

  get _bodyElement() {
    return document.body;
  }

}
