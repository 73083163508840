import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

/*
Use this controller when adding inline editing in a table.
The basics of how it works are the same as the bulk_table_controller, but with some tweaks.

Each row in the table will hold one form the the object of the row.
If the user edits one of the values (tipically they'll click in something with an action to the assign function),
the information about the attribute and its value(s) will be stored in two hidden fields and the form will be submitted.
Check out app/components/table_top_filters/guarantee_constitution_component.html.erb for an example of implementation.

One difference with the bulk_table_controller is that, if the cell of the table has a input (like a text field),
you don't need to define assignValueField target, because it will fetch the value from the input as long as it's
defined as fieldWithData target. Check out app/components/table_top_filters/date_picker_component.html.erb for an
example of implementation.

Another key difference with the bulk_table_controller is that it can manage multiple assignations at the same time.
To do that, you'd need to call the function assignMultipleFromFields, and it will clone the hidden fields
as many times as arguments are, so each one will arrive as an array or arguments. For example:
  - "assign_attribute" = ["guarantee_period_digit", "guarantee_period_display_unit"]
  - "assign_value" = ["2", "months"]

To use multiple assignations, you'd need to add fieldWithData to every field that needs to be passed along. Check out
app/components/table_top_filters/period_component.html.erb for an example of implementation.

These are the targets in this controller:
  · assignAttributeField: Hidden field to store the value of the name of the attribute we are updating.
    For instance, 'extension_date'
  · assignValueField: Hidden field to store the value of the value of the attribute we are updating.
    For instance, '2024/03/28'
  · form: The form that will be submitted once the previous fields are filled with the information.
  · fieldWithData: For input fields you don't need to use assignValueField, it'll get the value from the input itself
    if defined as this target.
  * hiddenFieldsContainer: This is the div where the inputs are located. It's used when cloning inputs,
    so it's easier to append the new cloned inputs.
*/

export default class extends Controller {
  static targets = ["form", "assignAttributeField", "assignValueField", "fieldWithData", "hiddenFieldsContainer"]

  connect(){
  }

  // Use this when the element to assign is a div or a link that has all the information in the data attributes
  assign(event){
    const assignAttribute = event.target.dataset.assignAttribute;
    const assignValue = event.target.dataset.assignValue;
    this._setValueAndSubmitForm(assignAttribute, assignValue)
  }

  // Use this when the element to assign is a field, to the value is
  // not in a data attribute but the value of the field itself
  assignFromField(event){
    const dataTarget = this._dataFieldTargetsUnder(event.target.parentElement)[0];
    const assignAttribute = dataTarget.dataset.assignAttribute;
    const assignValue = dataTarget.value;
    this._setValueAndSubmitForm(assignAttribute, assignValue)
  }

  // Use this when the element to assign is in multiple fields, so we need to send and array.
  assignMultipleFromFields(event){
    this._dataFieldTargetsUnder(event.target.parentElement).forEach((fieldElement, index) => {
      // When there are many, we create a new field for each of them if it does not exists
      if (this.assignAttributeFieldTargets[index] === undefined) {
        let clonedAssignAttributeField = this.assignAttributeFieldTargets[0].cloneNode(true)
        let clonedAssignValueField = this.assignValueFieldTargets[0].cloneNode(true)

        this.hiddenFieldsContainerTarget.append(clonedAssignAttributeField)
        this.hiddenFieldsContainerTarget.append(clonedAssignValueField)
      }

      this.assignAttributeFieldTargets[index].value = fieldElement.dataset.assignAttribute;
      this.assignValueFieldTargets[index].value = fieldElement.value;
    })

    Rails.fire(this.formTarget, 'submit');
  }

  // private

  _dataFieldTargetsUnder(element) {
    return element.querySelectorAll("[data-table-inline-update-target='fieldWithData']")
  }

  _setValueAndSubmitForm(assignAttribute, assignValue) {
    this.assignAttributeFieldTarget.value = assignAttribute;
    this.assignValueFieldTarget.value = assignValue;

    Rails.fire(this.formTarget, 'submit');
  }

}
