import { Controller } from "@hotwired/stimulus";
import { BeeSwarm, TreeMap, Gantt, toJSON } from "gobierto-vizzs";
import { Barchart, Linechart, Scatterplot } from "../vizzs";
import { mock } from "../helpers";

function tooltipTreemapFunction(d) {
  return `
    <div class="beeswarm-tooltip-id">${d.data.title}</div>
    <div class="beeswarm-tooltip-values">
      <span class="beeswarm-tooltip-date">${d.value.toLocaleString()}</span>
    </div>`;
};

function tooltipGanttFunction(d, data) {
  const group = data.filter((x) => x.index === d.index);
  return [
    `<a class="text-sm" href="${d.permalink}">${d.title}</a>`,
    ...group.map(g => `
    <div class="flex space-x-4 text-sm text-black">
      <span>${g.index}</span>
      <span>${g.step}</span>
      <span>${parseFloat(g.initial_amount_no_taxes).toLocaleString(undefined, { style: "currency", currency: "EUR" })}</span>
    </div>`)
  ].join("");
};

function itemTemplateTreemapFunction(d) {
  // since where using "count" for adding items, d.value is no longer useful
  // instead, sum the significant numeric property
  const sum = d.leaves().reduce((a, b) => a + +b.data.final_amount_no_taxes, 0);
  if (d.data["internal_contract_url"]) {
    return `
      <a class="text-white h-full no-underline block" href="${d.data.internal_contract_url}" target="_blank">
        <div class="underline">${d.data.title}</div>
        <div>${sum.toLocaleString(undefined, { style: "currency", currency: "EUR" })}</div>
      </a>`;
  }

  return [
    `<div><strong>${d.data.title}</strong></div>`,
    `<div>${sum.toLocaleString(undefined, { style: "currency", currency: "EUR" })}</div>`,
    d.children && `<div>${d.children?.length}</div>`,
  ].join("");
};

export default class extends Controller {
  static targets = [
    "bsTypeChart",
    "tmProcessTypeChart",
    "tmContractTypeChart",
    "tmStatusChart",
    "tmDepartmentChart",
    "tmEndDateChart",
    "bsSandboxChart",
    "tmSandboxChart",
    "gaSandboxChart",
    "barSandboxNumericChart",
    "barSandboxDateChart",
    "lineSandboxChart",
    "statCardLineChart",
    "contractsAmountBarChart",
    "contractsDateBarChart",
    "scatterSandboxChart",
    "scatterSandboxTypeAChart",
    "scatterSandboxTypeBChart",
    "scatterDateAndAmountByNumberOfProposals",
    "scatterDateAndAmountByDiscount"
  ];
  static values = { csvEndpoint: String, stop: Boolean };

  connect() {
    // in order to avoid load directly the vizzs
    if (!this.stopValue) {
      this.init();
    }
  }

  initFromEvent() {
    if(!this.loaded) {
      this.init()
    }
  }

  async init() {
    this.anchorTable = document.querySelector('[data-anchor="table"]');
    //Disables tab button until the vizzs are fully loaded.
    if(document.querySelector('[data-anchor="table"]')) {
      this._disableTabButton()
    }

    // if there's no csv, it fakes the request and get mock data
    const response = await (this.csvEndpointValue
      ? fetch(this.csvEndpointValue).then((r) => r.text())
      : new Promise((r) => r(mock())));
    const data = this.csvEndpointValue ? toJSON(response) : response;

    // specifics charts
    if (this.hasTmDepartmentChartTarget) {
      new TreeMap(this.tmDepartmentChartTarget, data, {
        group: ["department", "assignee_entity_name"],
        relation: "assignee_entity_name",
        id: "title",
        rootTitle: this.tmDepartmentChartTarget.dataset.title,
        tooltip: tooltipTreemapFunction,
        itemTemplate: itemTemplateTreemapFunction,
      });
    }

    if (this.hasTmEndDateChartTarget) {
      new TreeMap(this.tmEndDateChartTarget, data, {
        group: ["end_date_year", "end_date_month", "assignee_entity_name"],
        relation: "assignee_entity_name",
        id: "title",
        rootTitle: this.tmEndDateChartTarget.dataset.title,
        tooltip: tooltipTreemapFunction,
        itemTemplate: itemTemplateTreemapFunction,
      });
    }

    if (this.hasTmStatusChartTarget) {
      new TreeMap(this.tmStatusChartTarget, data, {
        group: ["internal_contract_state", "assignee_entity_name"],
        relation: "assignee_entity_name",
        id: "title",
        rootTitle: this.tmStatusChartTarget.dataset.title,
        tooltip: tooltipTreemapFunction,
        itemTemplate: itemTemplateTreemapFunction,
      });
    }

    if (this.hasTmProcessTypeChartTarget) {
      new TreeMap(this.tmProcessTypeChartTarget, data, {
        group: ["process_type", "assignee_entity_name"],
        relation: "assignee_entity_name",
        id: "title",
        rootTitle: this.tmProcessTypeChartTarget.dataset.title,
        tooltip: tooltipTreemapFunction,
        itemTemplate: itemTemplateTreemapFunction,
      });
    }

    if (this.hasTmContractTypeChartTarget) {
      new TreeMap(this.tmContractTypeChartTarget, data, {
        group: ["contract_type", "assignee_entity_name"],
        relation: "assignee_entity_name",
        id: "title",
        rootTitle: this.tmContractTypeChartTarget.dataset.title,
        tooltip: tooltipTreemapFunction,
        itemTemplate: itemTemplateTreemapFunction,
      });
    }

    if (this.hasBsTypeChartTarget) {
      new BeeSwarm(this.bsTypeChartTarget, data, {
        minBlockSize: 150,
        x: "end_date",
        y: "contract_type",
        value: "final_amount_no_taxes",
        relation: "assignee_entity_name",
        id: "title",
      });
    }

    if (this.hasStatCardLineChartTarget) {
      new Linechart(this.statCardLineChartTarget, data, {
        x: "date",
        y: ["total_tenders", "total_contracts"],
        tooltipYLabels: [I18n.charts.stat_card_line_chart.tenders, I18n.charts.stat_card_line_chart.contracts],
        tooltip: function(d){
          return [
             // format date to mm/YYYY
            `<div>${d[this.xAxisProp].toLocaleDateString().split("/").slice(1,3).join("/")}</div>`,
            this.yAxisProp.map((y, i) => `<div>${this.tooltipYLabels[i]}: ${d[y]}</div>`).join(""),
          ].filter(Boolean).join("");
        }
      });
    }

    if (this.hasContractsAmountBarChartTarget && this._tenderDateField(data) === 'tender_submission_date') {
      const filteredData = data.filter(this._hasValidFinalAmount);
      new Barchart(this.contractsAmountBarChartTarget, filteredData, {
        rootTitle: I18n.charts.bar_chart.by_amount_title,
        tooltipTitleProp: "title",
        tooltipUrlProp: "internal_url",
        legendTitle: I18n.charts.bar_chart.tender,
        yTitle: I18n.charts.bar_chart.discounts,
        x: "final_amount_no_taxes",
        y: "amount_difference_pct",
        z: "tender_submission_date",
      });
    }

    if (this.hasContractsDateBarChartTarget && this._tenderDateField(data) === 'tender_submission_date') {
      const filteredData = data.filter(this._hasValidFinalAmount);
      new Barchart(this.contractsDateBarChartTarget, filteredData, {
        rootTitle: I18n.charts.bar_chart.by_date_title,
        tooltipTitleProp: "title",
        tooltipUrlProp: "internal_url",
        legendTitle: I18n.charts.bar_chart.budget,
        yTitle: I18n.charts.bar_chart.discounts,
        x: "tender_submission_date",
        y: "amount_difference_pct",
        z: "initial_amount_no_taxes",
      });
    }

    if (this.hasScatterDateAndAmountByNumberOfProposalsTarget) {
      new Scatterplot(this.scatterDateAndAmountByNumberOfProposalsTarget, data, {
        x: this._tenderDateField(data),
        y: "initial_amount_no_taxes",
        z: "number_of_proposals",
        color: "process_type",
        zTitle: I18n.charts.scatterplot.number_of_proposals,
        tooltip: (d) => {
          const dateTooltip =
            d.submission_date
            ? d.submission_date.toLocaleDateString()
            : new Date(d.start_date).toLocaleDateString();
          return [
            `<a href="${d.internal_url}" target="_blank">${d.title}</a>`,
            `<div class="flex space-x-4">
              <span>${dateTooltip}</span>
              <span>${d.initial_amount_no_taxes.toLocaleString(undefined, { style: "currency", currency: "EUR" })}</span>
              <span>${d.number_of_proposals.toLocaleString()}</span>
            </div>`,
          ].join("")},
      });
    }

    if (this.hasScatterDateAndAmountByDiscountTarget) {
      const dataDiscount = data.map(d => ({ ...d, discount: +d.amount_difference_pct }))
      new Scatterplot(this.scatterDateAndAmountByDiscountTarget, dataDiscount, {
        x: this._tenderDateField(dataDiscount),
        y: "initial_amount_no_taxes",
        z: "amount_difference_pct",
        color: "process_type",
        zTitle: I18n.charts.scatterplot.amount_difference_pct,
        tooltip: (d) => {
          const dateTooltip =
            d.tender_submission_date
            ? d.tender_submission_date.toLocaleDateString()
            : d.start_date.toLocaleDateString();
          return [
            `<a href="${d.internal_url}" target="_blank">${d.title}</a>`,
            `<div class="flex space-x-4">
              <span>${dateTooltip}</span>
              <span>${d.initial_amount_no_taxes.toLocaleString(undefined, { style: "currency", currency: "EUR" })}</span>
              <span>${Number(d.amount_difference_pct).toLocaleString(undefined, { style: "percent" })}</span>
            </div>`,
          ].join("")},
      });
    }

    // sandboxing
    if (this.hasGaSandboxChartTarget) {
      new Gantt(this.gaSandboxChartTarget, data, {
        id: "title",
        x: "step",
        y: "index",
        from: "initial_date",
        to: "end_date",
        barHeight: 18,
        tooltip: d => tooltipGanttFunction(d, data),
      });
    }

    if (this.hasTmSandboxChartTarget) {
      new TreeMap(this.tmSandboxChartTarget, data, {
        group: ["group", "relation"],
      });
    }

    if (this.hasBsSandboxChartTarget) {
      new BeeSwarm(this.bsSandboxChartTarget, data, {
        minBlockSize: 150,
        margin: {
          top: 80,
        },
      });
    }

    if (this.hasBarSandboxNumericChartTarget) {
      new Barchart(this.barSandboxNumericChartTarget, data, {
        rootTitle: "<span>Contratos por <strong>importe de adjudicación</strong></span>",
        tooltipTitleProp: "title",
        tooltipUrlProp: "permalink",
        legendTitle: "Licitación",
        yTitle: "Descuentos",
        x: "initial_amount_no_taxes",
        y: "percentage_random",
        z: "submission_date",
      });
    }

    if (this.hasBarSandboxDateChartTarget) {
      new Barchart(this.barSandboxDateChartTarget, data, {
        rootTitle: "<span>Contratos por <strong>fecha de licitación</strong></span>",
        tooltipTitleProp: "title",
        tooltipcsvProp: "permalink",
        legendTitle: "Presupuesto base",
        yTitle: "Descuentos",
        x: "submission_date",
        y: "percentage_random",
        z: "initial_amount_no_taxes",
      });
    }

    if (this.hasLineSandboxChartTarget) {
      new Linechart(this.lineSandboxChartTarget, data, {
        x: "submission_date",
        y: ["number_of_batches", "number_of_proposals"],
        tooltipYLabels: ["Número de grupos", "Número de propuestas"]
      });
    }

    if (this.hasScatterSandboxChartTarget) {
      new Scatterplot(this.scatterSandboxChartTarget, data, {
        x: "date",
        y: "value",
        z: "percentage",
        color: "group",
      })
    }

    if (this.hasScatterSandboxTypeAChartTarget) {
      new Scatterplot(this.scatterSandboxTypeAChartTarget, data, {
        x: "submission_date",
        y: "initial_amount_no_taxes",
        z: "number_of_proposals",
        color: "process_type",
        tooltipTitleProp: "title",
        tooltipUrlProp: "permalink",
      })
    }

    if (this.hasScatterSandboxTypeBChartTarget) {
      const dataDiscount = data.map(d => ({ ...d, discount: +d.percentage_random }))
      new Scatterplot(this.scatterSandboxTypeBChartTarget, dataDiscount, {
        x: "submission_date",
        y: "initial_amount_no_taxes",
        z: "discount",
        color: "process_type",
        tooltipTitleProp: "title",
        tooltipUrlProp: "permalink",
      })
    }
    // status flag
    this.loaded = true
    this._enableTabButton()
  }

  // Return true only with items where final amount is higher than 60% of initial amount.
  _hasValidFinalAmount(item){
    return(
      !item.initial_amount_no_taxes ||
      !item.final_amount_no_taxes ||
      item.initial_amount_no_taxes*0.60 < item.final_amount_no_taxes
    )
  }

  _disableTabButton() {
    if(this.anchorTable) {
      this.anchorTable.classList.add("pointer-events-none")
    }
  }

  _enableTabButton() {
    if(this.anchorTable) {
      this.anchorTable.classList.remove("pointer-events-none")
    }
  }

  _tenderDateField(data) {
    return data.some(({ tender_submission_date }) => tender_submission_date !== '') ? "tender_submission_date" : "start_date"
  }

}
