import { Controller } from "@hotwired/stimulus"
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

export default class extends Controller {
  connect() {
    // obtain plugin
    const cc = initCookieConsent();

    // Configuration settings: https://github.com/orestbida/cookieconsent#layout-options--customization
    cc.run({
      current_lang: 'es',
      autoclear_cookies: true,
      page_scripts: true,
      autorun: true,
      force_consent: false,
      onAccept: () => window.dispatchEvent(new CustomEvent("vanilla-cookieconsent--changed")),
      onChange: () => window.dispatchEvent(new CustomEvent("vanilla-cookieconsent--changed")),
      gui_options: {
        consent_modal: {
          layout: 'box',               // box/cloud/bar
          position: 'bottom center',   // bottom/middle/top + left/right/center
          transition: 'zoom',          // zoom/slide
          swap_buttons: false          // enable to invert buttons
        },
        settings_modal: {
          layout: 'box',               // box/bar
          transition: 'zoom'           // zoom/slide
        }
      },
      languages: {
        'es': {
          consent_modal: {
            title: 'Usamos coookies!',
            description: 'Usamos algunas cookies en Gobierto. Algunas son para gestionar la sesión, y otras para ayudarnos a entender cómo se usa la herramienta para ayudarnos a mejorar. <button type="button" data-cc="c-settings" class="cc-link">Déjame elegir</button>',
            primary_btn: {
              text: 'Aceptar todas',
              role: 'accept_all'              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Rechazar todas',
              role: 'accept_necessary'        // 'settings' or 'accept_necessary'
            }
          },
          settings_modal: {
            title: 'Preferencias de cookie',
            save_settings_btn: 'Guardar preferencias',
            accept_all_btn: 'Aceptar todas',
            reject_all_btn: 'Rechazar todas',
            close_btn_label: 'Cerrar',
            cookie_table_headers: [
              { col1: 'Nombre' },
              { col2: 'Dominio' },
              { col3: 'Expira' },
              { col4: 'Descripción' }
            ],
            blocks: [
              {
                title: 'Uso de las cookies 📢',
                description: 'Usamos cookies para garantizar las funciones básicas de la herramienta y entender cómo se usa para poder merjorarla. Puedes leer nuestra política de <a href="/legal/cookies" class="cc-link">cookies</a>.'
              }, {
                title: 'Cookies estrictamente necesarias',
                description: 'Sin ellas el site no puede funcionar. Fundamentalmente se usan para poder gestionar tu sesión',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                }
              }, {
                title: 'Cookies de analítica',
                description: 'Utilizamos servicios para entender cómo se usa la herramienta y poder manejarla. Ningún dato sale de este servicio ni se usa para más fines',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_gcl',
                    col2: 'google.com',
                    col3: '1 año',
                    col4: 'Servicio de publicidad de Google',
                    domain: window.location.host.split(".").slice(-2).join("."), // https://github.com/orestbida/cookieconsent/issues/568
                    is_regex: true
                  },
                  {
                    col1: '^ph_',
                    col2: 'posthog.com',
                    col3: '1 año',
                    col4: 'Servicio de analítica',
                    domain: window.location.host.split(".").slice(-2).join("."), // https://github.com/orestbida/cookieconsent/issues/568
                    is_regex: true
                  }
                ]
              }, {
                title: 'Más información',
                description: 'Para más detalles visita nuestra página sobre <a class="cc-link" href="/legal/cookies">cookies</a>.',
              }
            ]
          }
        }
      }
    });
  }
}
