import { Controller } from "@hotwired/stimulus"

/**
 * This controller is used in the /tu-organizacion/setup page to update the fixed panel that allows
 * you to follow the selected keywords/cpvs.
 *
 * Targets:
 * - checkbox: The checkboxes representing the selected keywords/cpvs
 * - message: The message that is displayed when at least one checkbox is checked
 * - count: The element that displays the number of selected keywords/cpvs
 *
 * Sample usage: app/views/suggestions_lists/show/_report.html.erb
 */
export default class extends Controller {
  static targets = ["checkbox", "message", "count"]

  connect() {
    this._handleMessageVisibility();
    this._updateCount();
  }

  onCheck() {
    this._handleMessageVisibility();
    this._updateCount();
  }

  // private

  _handleMessageVisibility() {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)){
      this.messageTarget.classList.remove("hidden");
    } else {
      this.messageTarget.classList.add("hidden");
    }
  }

  _updateCount() {
    const checkedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;

    this.countTarget.textContent = checkedCount;
  }
}
