import { Controller } from "@hotwired/stimulus"
import { slugify } from "../helpers"

/**
 * This controller creates a TOC (table-of-contents)
 * from the heading tags (h2 and h3) found in the "content"
 *
 * @example
 * <div data-controller="toc" data-toc-active-class="active">
 *  <div data-toc-target="content">...</div>
 *  <div data-toc-target="container" class="toc-container"></div>
 * </div>
 *
 * Add this attribute to change the CSS active classes:
 * @data-attribute: data-toc-active-class="other-active-class another-one"
 */
export default class extends Controller {
  static targets = ["content", "container"]
  static classes = [ "active" ]

  connect() {
    if (!this.hasContentTarget || !this.hasContainerTarget) return

    const headings = Array.from(this.contentTarget.querySelectorAll("h2, h3"));

    const toc = document.createElement("ul");
    const links = [];

    let currentLi;

    headings.forEach((heading) => {
      // Add ID and scroll margin to the heading
      const slug = slugify(heading.innerText);
      heading.id = slug;

      // Create a list item
      const li = document.createElement("li");

      // Create a link
      const a = document.createElement("a");
      a.innerText = heading.innerText;
      a.href = `#${slug}`;
      links.push(a);

      li.appendChild(a);

      if (heading.tagName.toLowerCase() === "h2") {
        toc.appendChild(li);
        currentLi = li;
      } else {
        let ul = currentLi.querySelector("ul");

        // If there is no nested ul, create one
        if (!ul) {
          ul = document.createElement("ul");
          currentLi.appendChild(ul);
        }

        ul.appendChild(li);
      }
    });

    this.containerTarget.appendChild(toc);

    // Define observer for highlighting TOC links
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting, target }) => {
          if (isIntersecting) {
            const activeLink = document.querySelector(`a[href="#${target.id}"]`);
            links.forEach(({ classList }) => classList.remove(...this.activeClasses));
            activeLink.classList.add(...this.activeClasses);
          }
        });
      },
      { rootMargin: "-20% 0% -80%" }
    );

    // Observe all headings
    headings.forEach(heading => observer.observe(heading));
  }
}
