import { Controller } from "@hotwired/stimulus"

// - This controller is used when we want a checkbox to alter other element/s in the DOM (disabling or hidding them)
//
// Usage
// Targets:
// - checkbox: The checkbox input that will control the others
// - disableElement: One or more inputs that will be enabled/disable when the checkbox changes.
// - hideElement: One or more inputs that will be displayed/hidden when the checkbox changes.
//
// Sample usage in app/views/internal_contracts/shared/form/_extensions.html.erb

export default class extends Controller {
  static targets = [ "checkbox", "disableElement", "hideElement"]

  connect(){
    this._handleCurrentStatus();
  }

  toggle(event) {
    this._handleCurrentStatus();
  }

  // private

  _handleCurrentStatus(){
    this._handleDisableElements();
    this._handleHideElements();
  }

  _handleDisableElements(){
    if (this.checkboxTarget.checked) {
      this.disableElementTargets.forEach(function(element){
        element.removeAttribute("disabled");
      })
    } else {
      this.disableElementTargets.forEach(function(element){
        element.disabled = true;
        if (element.type === "number") element.value = "";
      });
    }
  }

  _handleHideElements(){
    if (this.checkboxTarget.checked) {
      this.hideElementTargets.forEach(function(element){
        element.classList.remove("hidden");
      })
    } else {
      this.hideElementTargets.forEach(function(element){
        element.classList.add("hidden");
        if (element.type === "number") element.value = "";
      });
    }
  }
}
