import { Controller } from "@hotwired/stimulus"

// This controller is only used in projects form to set the process type select field value
// based on the value of the contracting system select field.

export default class extends Controller {
  static targets = [ "processTypeField" ]

  connect(){
  }

  maybeUpdate(event){
    const newValue = event.target.value

    if (newValue === "2" || newValue === "4") {
      this.processTypeFieldTarget.value = "12" // SDA
    } else if (newValue === "1" || newValue === "3") {
      this.processTypeFieldTarget.value = "7" // AM
    }
  }

}
