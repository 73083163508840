import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['insertLocation', 'template', 'form', 'sortableParent']

  static values = {
    wrapperSelector: String
  }

  initialize () {
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper'
  }

  connect () {
    if (this.hasSortableParentTarget) {
      new Sortable(this.sortableParentTarget, {
        handle: '.js-sortable-handle',
        filter: ".js-sortable-ignore",
        onEnd: (event) => {
          const sortableElements = document.querySelectorAll(".js-sortable-draggable")

          for(var i = 0; i < sortableElements.length; i++){
            var element = sortableElements[i];
            element.querySelector("input[name*='position']").value = i;
          }

          Rails.fire(this.formTarget, 'submit');
        }
      })
    }
  }

  add (event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.insertLocationTarget.insertAdjacentHTML('beforebegin', content)

    const editModeEvent = new CustomEvent("edit-mode", { detail: {formId: this.formTarget.id} })
    window.dispatchEvent(editModeEvent)
  }

  remove (event) {
    Rails.stopEverything(event)
    const confirmDialog = confirm(event.target.closest("div[data-confirm-text]").dataset.confirmText)

    if (confirmDialog === true) {
      const wrapper = event.target.closest(this.wrapperSelector)

      if (wrapper.dataset.newRecord === 'true') {
        wrapper.remove()
      } else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.classList.add('hidden')
        Rails.fire(this.formTarget, 'submit');
      }
    }
  }

  // private

  _endSorting(event){
    const sortableElements = document.querySelectorAll(".js-sortable-draggable")

    for(var i = 0; i < sortableElements.length; i++){
      var element = sortableElements[i];
      element.querySelector("input[name*='position']").value = i;
    }

    Rails.fire(this.formTarget, 'submit');
  }
}
