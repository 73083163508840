import React from "react";

const tools = [
  {
    title: "Strike",
    onClick: () => editor.chain().focus().toggleStrike().run()
  },
  {
    title: "Bold",
    onClick: () => editor.chain().focus().toggleBold().run()
  },
  {
    title: "Italic",
    onClick: () => editor.chain().focus().toggleItalic().run()
  },
]

function Button({ onClick, title }) {
  return (
    <button
      onClick={onClick}
      title={title}
      className="button button-secondary button-xs"
    >
      {title}
    </button>
  );
}

export default function Toolbar(props) {
  return (
    <div className="flex items-center gap-2 p-2 bg-white rounded shadow border">
      {tools.map(tool => <Button key={tool.title} {...props} {...tool} />)}
    </div>
  );
}
