import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    // if you don't specify the text to be copied, it uses the sourceTarget text content
    const clipboard = event.params.text ? event.params.text : this.sourceTarget.innerText;
    navigator.clipboard.writeText(clipboard).then(() => this.dispatch("copy"))
  }
}
