import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "iframeContainer", "link", "idField", "expirationDateField" ]
  static values = {
    certUrl: {
      type: String,
      default: ''
    }
  }

  connect(){
    window.addEventListener("message", this.receiveMessage.bind(this), false);
  }

  loadIframe(event){
    event.preventDefault()
    this.iframeContainerTarget.innerHTML = ''

    let iframeElement = document.createElement('iframe');
    iframeElement.src = this.certUrlValue;
    this.iframeContainerTarget.appendChild(iframeElement);
  }

  receiveMessage(event){
    this.idFieldTarget.value = event.data.id
    this.expirationDateFieldTarget.value = event.data.expiration_date

    Rails.fire(this.formTarget, 'submit');
  }

}
