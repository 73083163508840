import { Controller } from "@hotwired/stimulus"

/**
 * This controller is used to activate/deactivate tabs and scroll to the corresponding section.
 *
 * Usage:
 * Add these attributes to the container element:
 *   data-controller="anchor-scroll-navigation"
 *   data-anchor-scroll-navigation-inside-modal-value="true/false"
 *   data-anchor-scroll-navigation-scroll-value="true/false" (optional, default is true)
 *
 * Add these attributes to tab buttons:
 *   data-anchor="anchor_name"
 *   data-anchor-scroll-navigation-target="anchorScrollButton"
 *   data-action="click->anchor-scroll-navigation#scrollToSection"
 *
 * Add these attributes to tab contents:
 *   id="anchor_name"
 *   data-anchor-scroll-navigation-target="anchorScrollSection"
 */

export default class extends Controller {
  static targets = ["anchorScrollButton", "anchorScrollSection"]
  static values = {
    insideModal: Boolean,
    scroll: { type: Boolean, default: true }
  }

  connect() {
    this.activateFirstButton()
    this.initInteractionObserver()
    this.bindButtonClickHandlers()
  }

  disconnect() {
    this.observer.disconnect()
  }

  initInteractionObserver() {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      rootMargin: "-20% 0% -80% 0%",
      threshold: [0]
    })
    this.anchorScrollSectionTargets.forEach(section => this.observer.observe(section))
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.activateButtonForSection(entry.target.id)
      }
    })
  }

  activateButtonForSection(sectionId) {
    this.anchorScrollButtonTargets.forEach(button => {
      const buttonSectionId = button.dataset.anchor
      button.classList.toggle("active", buttonSectionId === sectionId)
    })
  }

  activateFirstButton() {
    if (this.hasAnchorScrollButtonTarget) {
      this.anchorScrollButtonTarget.classList.add("active")
    }
  }

  bindButtonClickHandlers() {
    this.anchorScrollButtonTargets.forEach(button => {
      button.addEventListener('click', this.scrollToSection.bind(this))
    })
  }

  scrollToSection(event) {
    event.preventDefault()
    const target = event.target
    const sectionId = target.dataset.anchor
    const section = this.anchorScrollSectionTargets.find(({ id }) => id === sectionId)
    if (!section) return

    if (this.insideModalValue) {
      const options = { block: "start" }
      if (this.scrollValue) options.behavior = "smooth"
      section.scrollIntoView(options)
      return
    }

    const scrollMarginTop = parseInt(getComputedStyle(section).scrollMarginTop, 10) || 0
    const scrollPaddingTop = parseInt(getComputedStyle(section).scrollPaddingTop, 10) || 0
    const offset = scrollMarginTop + scrollPaddingTop

    if (this.scrollValue) {
      window.scrollTo({
        top: section.getBoundingClientRect().top + window.scrollY - offset,
        behavior: "smooth"
      })
    } else {
      section.scrollIntoView({ block: "start" })
    }
  }
}
