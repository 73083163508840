import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ 'form' ]
  static values = { url: String }

  connect(){
    this._maybeHideSpinner();
  }

  _maybeHideSpinner(){
    const spinner = document.querySelector(".js-spinner-search");
    if (!spinner.classList.contains('hidden')) {
      spinner.classList.toggle('hidden');
    }
  }
}
