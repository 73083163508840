import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "label", "inputContainer" ]

  toggle(event){
    event.preventDefault();

    this.labelTargets.concat(this.inputContainerTargets).forEach( (element, index) => {
      element.classList.toggle('hidden');
    });

    this.inputContainerTargets.forEach( (element, index) => {
      const inputElement = element.querySelector("input") || element.querySelector("select")

      if (inputElement && inputElement.nodeName === 'SELECT') {
        // Not possible at the moment
      } else if(inputElement && inputElement.nodeName === 'INPUT'){
        inputElement.focus();
        this._moveCursorToEnd(inputElement);
      }
    });

    const editModeEvent = new CustomEvent("edit-mode", { detail: {formId: event.target.closest("form").id} })
    window.dispatchEvent(editModeEvent)
  }


  // private
  _moveCursorToEnd(element){
    if (typeof element.selectionStart === "number") {
        element.selectionStart = element.selectionEnd = element.value.length;
    } else if (typeof element.createTextRange !== "undefined") {
        element.focus();
        let range = element.createTextRange();
        range.collapse(false);
        range.select();
    }
  }
}
