import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "inputElement", "buttonElement", "buttonAllElement", "formElement" ]

  connect() {
    if(this.inputElementTargets.every(({ checked }) => checked === true)) {
      this.buttonElementTargets.forEach(button => button.textContent = I18n.sidebar.only)
    } else {
      this.buttonAllElementTarget.classList.remove('hidden')
    }
  }

  selectOneOrAll(e) {
    if(e.currentTarget.textContent.replace(/\s/g, '') === I18n.sidebar.only) {
      this.inputElementTargets.forEach(input => input.checked = false)
      e.currentTarget.parentElement.firstElementChild.control.checked = true
    } else if(e.currentTarget.textContent.replace(/\s/g, '') === I18n.sidebar.all) {
      this.inputElementTargets.forEach(input => input.checked = true)
      this.buttonElementTargets.forEach(button => button.textContent = I18n.sidebar.only)
    }
  }

  checkIfAllInputsAreChecked() {
    if(this.inputElementTargets.every(({ checked }) => checked === true)) {
      this.buttonElementTargets.forEach(button => button.textContent = I18n.sidebar.only)
    } else if(this.inputElementTargets.every(({ checked }) => checked === false)) {
      this.buttonAllElementTarget.classList.remove('hidden')
    }
  }

  _fireForm(event){
    const spinner = document.querySelector(".js-spinner-search");
    if (spinner) { spinner.classList.remove('hidden') };

    Rails.fire(this.formElementTarget, 'submit');
  }
}
