import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "numberExtensionsSelect", "extensionBlock", "errorMessage" ]

  submitForm(event){
    if (!this._isValid()){
      event.preventDefault();
      this.errorMessageTarget.classList.remove("hidden");

      this.errorMessageTarget.parent().scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }

  // private

  _isValid(){
    return this._extensionsBlocksCount() <= this._extensionsSelectNumber()
  }

  _extensionsBlocksCount(){
    return this.extensionBlockTargets.filter(function(item){
      return !item.classList.contains('hidden')
    }).length;
  }

  _extensionsSelectNumber(){
    const selectValue = this.numberExtensionsSelectTarget.value;

    if (this.numberExtensionsSelectTarget.value == '') {
      return 0;
    } else {
      return parseInt(this.numberExtensionsSelectTarget.value, 10);
    }
  }

}
