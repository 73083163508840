import { Controller } from "@hotwired/stimulus"

// - This controller is used when we want a select to alter the options of a second select
//
// Usage
// Targets:
// - select: The select input that will control the other
// - subselect: The select input that will be controlled
// - container: The container that will be displayed/hidden when the subselect has options
//
// Sample usage in app/views/internal_contracts/shared/form/_extensions.html.erb

export default class extends Controller {
  static targets = [ "select", "subselect", "container"]

  connect(){
    this._handleHideElements();
  }

  toggle() {
    this._handleHideElements(true);
  }

  // private

  _handleHideElements(toggle) {
    const group = +this.selectTarget.value;
    const options = this.subselectTarget.options;
    let hasOptions = false;

    if (toggle) this.subselectTarget.value = "";

    for (let option of options) {
      if (option.dataset.selectDuoGroup == group) {
        option.style.display = "block";
        hasOptions = true;
      } else {
        option.style.display = "none";
      }
    }

    if (hasOptions) {
      this.containerTarget.style.display = "block";
    } else {
      this.containerTarget.style.display = "none";
    }
  }
}
