import { Controller } from "@hotwired/stimulus"

// - This controller is used when we want a checkbox to set the value to other element/s in the DOM
//
// Usage
// Targets:
// - checkbox: The checkbox input that will control the others
// - setElement: One or more inputs whose value will be set.
//
// Sample usage in app/views/internal_contracts/shared/form/_extensions.html.erb

export default class extends Controller {
  static targets = [ "checkbox", "setElement"]

  setValue(event) {
    if (this.checkboxTarget.checked) {
      this.setElementTargets.forEach(function(element){
        if (element.value == "" || element.value == undefined) {
          const newValue = element.getAttribute("data-checkbox-setter-new-value")
          element.value = newValue;
          element.dispatchEvent(new Event('change'));
        }
      })
    }
  }
}
