import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["template", "list"]
  static values = { updateUrl: String }

  addNew(event){
    event.preventDefault();

    const newIndex = document.querySelectorAll("tr.invoicing_milestone").length
    const content = this.templateTarget.innerHTML.replace(/CHILD_INDEX/g, newIndex);

    const afterElement = this.listTarget;
    afterElement.insertAdjacentHTML('beforeend', content)
  }

  delete(event){
    event.preventDefault();
    const parentTrElement = event.target.closest("tr")

    if (parentTrElement.classList.contains("js-dynamic")) {
      parentTrElement.parentNode.removeChild(parentTrElement);
    } else {
      parentTrElement.classList.add("hidden");
      // In order to not validate the hidden fields
      parentTrElement.querySelectorAll('input').forEach(element => element.required = false);
      parentTrElement.querySelector(".invoicing_milestone_destroy_field").value="true";
    }
  }
}
