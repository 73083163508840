import { Controller } from "@hotwired/stimulus";
import { Chronogram } from "../vizzs";

/**
* This controller is used to create a chronogram vizz
* The chronogram is splitted into two targets, table and chart.
* You can enable/disable the animation via params:
    @data-attribute: data-chronogram-animation-value="false"

* @markup-example:

  <div
    data-controller="chronogram"
    data-chronogram-animation-value="false"
  >
    <div class="w-1/2">
      <table
        data-chronogram-target="table"
      >
      </table>
    </div>
    <div class="w-1/2">
      <div
        data-chronogram-target="chart">
      </div>
    </div>
  </div>
**/

export default class extends Controller {
  static targets = [ "chart", "table" ]
  static values = {
    animation: {
      type: Boolean,
      default: true
    }
  }

  async connect() {
    const tbody = this.tableTarget.querySelector("tbody")

    const data = Array.from(tbody.querySelectorAll("[data-from]")).map(
      (node) => {
        const { from, to } = node.dataset;
        return { from, to };
      }
    );

    // In order to align with the table head
    const chronogram = new Chronogram(this.chartTarget, this.animationValue, { x: "from", y: "to" })
    chronogram.build(data)
  }
}
