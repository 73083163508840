import { Controller } from "@hotwired/stimulus";
/**
 * This is the controller that contains all the functionalities
 * of a table: column sorting and resizing, and "browseable" table.
 *
 *
 * Add these data attributes to the <table> element:
 *  @data-attribute: data-controller="table-manager"
 *  @data-attribute: data-table-manager-target="tableElement"
 * You can add optional functionality to be able to
 *  navigate between table rows with this attribute:
 *    @data-attribute: data-table-browseable="true"
 *
 * Add this attribute to the <tr> of the <thead>:
 *  @data-attribute: data-table-manager-target="thTrElement"
 *
 * Add this attribute to the <tr> of the <thead>:
 *  @data-attribute: data-table-manager-target="thTrElement"
 *
 * Add these attributes to all the <th> of the <thead>
 *  @data-attribute: data-table-manager-target="thElement"
 *  @data-attribute: data-action="click->table-manager#sortTable"
 *
 * Add this attributes to all the <tr> of the <tbody>
 *  @data-attribute: data-table-manager-target="trElement"
 *
 * Note: some parts (the header and each row) of the tables are created with ViewComponents in Rails. This example is the final result.
 *
 * @markup-example
 *   <table
 *     data-controller="table-manager"
 *     data-table-manager-target="tableElement"
 *     data-table-browseable="true"
 *     class="items-list align-top-cells"
 *   >
 *   <thead>
 *    <tr data-table-manager-target="thTrElement">
 *      <th
 *        data-table-manager-target="thElement"
 *        data-action="click->table-manager#sortTable"
 *      >
 *        Contracto
 *      </th>
 *      <th
 *        data-table-manager-target="thElement"
 *        data-action="click->table-manager#sortTable"
 *      >
 *        Presupuesto
 *      </th>
 *    </tr>
 *    <tbody>
 *      <tr
 *        data-table-manager-target="trElement"
 *        class="table-sort-row ic_1 cursor-pointer border-l-2 border-transparent js-table-tr-highlighted bg-blue/10">
 *        <td>Tratamientos aereos para control de mosquitos en marismas mareales. campañas 2021-2022.</td>
 *        <td>25,00 €</td>
 *      </tr>
 *      <tr
 *        data-table-manager-target="trElement"
 *        class="table-sort-row ic_1 cursor-pointer border-l-2 border-transparent js-table-tr-highlighted bg-blue/10">
 *        <td>Servicio de Asistencia Técnica para la coordinación y gestión del proyecto europeo "ECO-CICLE"</td>
 *        <td>32.628,10 €</td>
 *      </tr>
 *    </tbody>
 *   </table>
*/

const trClassNames = ['js-table-tr-highlighted', 'js-table-tr-selected', 'js-table-tr-last-selected'];
const { UP, DOWN, SPACEBAR, ENTER, E } = { UP: "ArrowUp", DOWN: "ArrowDown", SPACEBAR: " ", ENTER: "Enter", E: "e" };

export default class extends Controller {
  static targets = ["tableElement", "thElement", "trElement", "thTrElement", "truncateElement"];

  initialize() {
    this._handleKeyPressEvents = this._handleNavigateTable.bind(this);
    this._handleClickEvent = this._handleClickSelectRow.bind(this);
  }

  connect() {
    const { children: cols } = this.tableElementTarget.querySelector('tr') || {};

    const tableHeight = this.tableElementTarget.offsetHeight;

    Array.from(cols).forEach((column, index) => {
      if (index !== cols.length - 1) {
        const div = this._createDiv(tableHeight);
        column.appendChild(div);
        column.style.position = 'relative';
        this._setListeners(div);
      }
    })

    if(this.tableElementTarget.dataset.tableBrowseable && this.trElementTargets.length > 0) {
      //The first row of the table is highlighted
      this.trElementTargets[0].classList.add(trClassNames[0], "bg-blue/10");
      window.addEventListener('keydown', this._handleKeyPressEvents)
      window.addEventListener('click', this._handleClickEvent)

      this.trElementTargets.forEach(row => {
        const trInput = row.querySelector('input')
        if(trInput && trInput.checked) {
          row.classList.add("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
        }
      });
    }
  }

  disconnect() {
    window.removeEventListener('keydown', this._handleKeyPressEvents)
    window.removeEventListener('click', this._handleClickEvent)
  }

  _handleNavigateTable(event) {
    const trFocused = this.trElementTargets.find(row => row.classList.contains(trClassNames[0]));
    const { key, target: { type, tagName } } = event
    /* When typing in the INPUTS of the sidebar,
    events aren't triggered to avoid conflicts
    with the SPACEBAR, E, and ENTER keys.*/
    if (tagName === 'INPUT' && type === 'text' || tagName === 'TEXTAREA' || tagName === 'INPUT' && type === 'submit') {
      return;
    }
    switch (key) {
      case UP:
        event.preventDefault();
        this._navigateRows(trFocused, UP)
        break;
      case DOWN:
        event.preventDefault();
        this._navigateRows(trFocused, DOWN)
        break;
      case SPACEBAR:
        event.preventDefault();
        this._selectRow(event)
        break;
      case ENTER:
        event.preventDefault();
        this._navigateToContract(ENTER)
        break;
      case E:
        event.preventDefault();
        //If the modal is open don't trigger the method to avoid conflicts with the shortcuts_controller
        if(document.body.classList.contains('form-modal')) {
          return;
        }
        this._navigateToContract(E)
        break;
    }
  }

  //The user can select the row by clicking anywhere in it.
  _handleClickSelectRow(event) {
    const { target, target: { tagName, id, checked, dataset } } = event

    const toggleActions = ["toggleButton", "click->toggle#toggle"]

    if(!this.tableElementTarget.contains(target) || this.thTrElementTarget.contains(target) || toggleActions.some(element => Object.values({ ...dataset }).includes(element))) {
      return;
    }
    if(id === 'bulk_update_select_all') {
      this._selectAllRows(checked)
      return;
    }

    const trParent = target.closest('tr');
    const htmlTags = ['svg', 'A', 'INPUT'];
    if(!htmlTags.includes(tagName) && trParent) {
      this._addClassesToSelectRow(trParent)
    }
    if(tagName === 'INPUT' && !checked) {
      trParent.classList.remove("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
    }
    if(tagName === 'INPUT' && checked) {
      this.trElementTargets.forEach(row => { row.classList.remove(trClassNames[2], "border-l-blue-400") });
      trParent.classList.add("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400");
    }
  }

  _selectAllRows(checked) {
    if(checked) {
      this.trElementTargets.forEach(row => {
        row.classList.add("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
      })
    } else {
      this.trElementTargets.forEach(row => {
        row.classList.remove("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
      });
    }
  }

  _addClassesToSelectRow(element) {
    const trInput = element.querySelector('input');
    //The control table contains checkbox but plans table not
    if(trInput) {
      if (element && !trInput.checked) {
        this.trElementTargets.forEach(row => { row.classList.remove(trClassNames[2], "border-l-blue-400") });
        element.classList.add("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
        trInput.checked = true
        this._initChangeEvent(trInput)
      } else {
        element.classList.remove("bg-blue/10", trClassNames[1], "border-l-blue-400")
        trInput.checked = false
        this._initChangeEvent(trInput)
      }
    } else {
      if (!element.classList.contains(trClassNames[1])) {
        this.trElementTargets.forEach(row => { row.classList.remove(trClassNames[2], "border-l-blue-400") });
        element.classList.add("bg-blue/10", trClassNames[1], trClassNames[2], "border-l-blue-400")
      } else {
        element.classList.remove("bg-blue/10", trClassNames[1], "border-l-blue-400")
      }
    }
  }

  //Dispatch the change event when select a row with the keyboard or click
  _initChangeEvent(input) {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    input.dispatchEvent(evt);
  }

  //User can navigate across the table depending if pressing up or down arrows
  _navigateRows(trFocused, keyPress) {
    const { previousElementSibling, nextElementSibling } = trFocused;
    /*If the user presses ArrowUp we only need the previousSibling,
      and if presses ArrowDown we need the nextSibling */
    const elementSibling =
      keyPress === UP
        ? previousElementSibling
        :  keyPress === DOWN
        ? nextElementSibling
        : null;

    /*The previousSibling does not exist if the user is in the first row,
    nor does the nextSibling exist if the user is in the last row.*/
    if ([UP, DOWN].includes(keyPress) && !elementSibling) {
      return;
    }
    if ([UP, DOWN].includes(keyPress) && elementSibling) {
      if(trFocused.classList.contains(trClassNames[1])) {
        trFocused.classList.remove(trClassNames[0], "border-l-blue-400");
      } else {
        trFocused.classList.remove(trClassNames[0], "bg-blue/10", "border-l-blue-400");
      }
      elementSibling.classList.add(trClassNames[0], "bg-blue/10", "border-l-blue-400");
      this._scrollTable(trFocused, keyPress)
    }
  }

  /*Replaces native scrolling when using the up and down arrows.
  When the DOWN arrow is pressed, get the bottom position of the row,
  and its height is added by two of the row. If the result is greater
  than the window's height, it's scrolled down.
  When the UP arrow is pressed, get the top position of the row. If the
  result is greater than the row height, it's scrolled up.*/
  _scrollTable(trElement, keyPress) {
    const { top, bottom, height } = trElement.getBoundingClientRect();
    const conditionKey =
      keyPress === DOWN
        ? (bottom + (height * 2)) > window.innerHeight
        : keyPress === UP
        ? (top / 2) < height
        : null

    const scrollTopKey =
      keyPress === DOWN
        ? (window.scrollY + (window.innerHeight / 2))
        : keyPress === UP
        ? (window.scrollY - (window.innerHeight / 2))
        : null

    if(conditionKey) {
      window.scrollTo({
        top: scrollTopKey
      });
    }
  }

  //If user presses the spacebar add classes to selected the row.
  _selectRow() {
    const trHighlighted = this.trElementTargets.find(row => row.classList.contains(trClassNames[0]));
    this._addClassesToSelectRow(trHighlighted)
  }

  //If the user presses enter: should navigate to the contract/project modal
  //If the user presses 'e': should navigate directly to edit screen of the contract/project
  _navigateToContract(keyPress) {
    //Selects the <a> element depending on the key pressed by the user
    const trSelectedLink =
      keyPress === ENTER
      ? document.querySelector(`.${trClassNames[0]} td a`)
      : document.querySelector(`.${trClassNames[0]} td .open-modal-edit`);

    if (trSelectedLink) {
      trSelectedLink.click()
      const trSelected = this.trElementTargets.find(row => row.classList.contains(trClassNames[1]));
    }
  }

  _setListeners(div) {
    let pageXSelected
    let curCol;
    let nxtCol;
    let curColWidth;
    let nxtColWidth;
    let cellSelected;
    let nxtColSelected;
    const tableWidth = this.tableElementTarget.clientWidth;
    div.addEventListener('mousedown', (e) => {
      const { pageX, target: { parentElement } } = e
      curCol = parentElement;
      nxtCol = curCol.nextElementSibling;
      pageXSelected = pageX;
      cellSelected = parentElement.cellIndex;
      nxtColSelected = curCol.nextElementSibling.cellIndex;
      const padding = this._paddingDiff(curCol);

      curColWidth = curCol.offsetWidth - padding;
      if (nxtCol) {
        nxtColWidth = nxtCol.offsetWidth - padding;
      }
    });

    div.addEventListener('mouseover', (e) => e.target.style.borderRightWidth = '2px')

    div.addEventListener('mouseout', (e) => e.target.style.borderRightWidth = '0')

    document.addEventListener('mousemove', (e) => {
      if (curCol) {
        const diffX = e.pageX - pageXSelected;
        const getTruncateElement = Array.from(this.trElementTargets[0].children).filter(element => Array.from(element.children).some(element => element.classList.contains('truncate')))
        const filterTruncateElement = Array.from(this.trElementTargets[0].children).filter(item => !getTruncateElement.includes(item));
        const totalWidth = filterTruncateElement.reduce((total, element) => total + element.clientWidth, 0);
        this.trElementTargets.forEach(row => {
          const { cells } = row;
          for (let element of cells[cellSelected].children) {
            if(element.classList.contains('truncate')) {
              element.style.maxWidth = `${(tableWidth - (totalWidth + 24))}px`;
              element.style.width = `${(curColWidth + diffX)}px`;
            }
          }
        })

        if (nxtCol) {
          nxtCol.style.width = `${(nxtColWidth - (diffX))}px`;
          this.trElementTargets.forEach(row => {
            const { cells } = row;
            for (let element of cells[nxtColSelected].children) {
              if(element.classList.contains('truncate')) {
                element.style.width = `${(curColWidth - diffX)}px`;
              }
            }
          })
        }

        curCol.style.width = `${(curColWidth + diffX)}px`;
      }
    });

    document.addEventListener('mouseup', (e) => {
      curCol = undefined;
      nxtCol = undefined;
      pageXSelected = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined;
      cellSelected = undefined;
      nxtColSelected = undefined;
    });
  }

  _createDiv(height) {
    let div = document.createElement('div');
    div.classList.add('table-resizer')
    div.style.height = `${height}px`;
    return div;
  }

  _paddingDiff(col) {

    if (this._getStyleVal(col, 'box-sizing') == 'border-box') {
      return 0;
    }

    const padLeft = this._getStyleVal(col, 'padding-left');
    const padRight = this._getStyleVal(col, 'padding-right');
    return (parseInt(padLeft) + parseInt(padRight));

  }

  _getStyleVal(elm, css) {
    return (window.getComputedStyle(elm, null).getPropertyValue(css))
  }

  //Adaptation -> Sorting HTML TABLE: https://stackoverflow.com/a/49041392
  sortTable(e) {
    if (e.target.className.includes('table-resize')) {
      return
    }
    e.stopPropagation()
    const tbody = this.tableElementTarget.querySelector('tbody')
    const asc = e.currentTarget.classList.contains("active") ? false : true
    Array.from(this.tableElementTarget.querySelectorAll('tr.table-sort-row'))
      .sort(this._comparer(Array.from(e.currentTarget.parentNode.children).indexOf(e.currentTarget), asc, e))
      .forEach(tr => tbody.appendChild(tr));
  }

  _comparer(idx, asc, e) {
    this._sortDirection(asc, e)
    const that = this
    return function(a, b) {
      return function(v1, v2) {
        return v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2);
      }(that._getValue(asc ? a : b, idx), that._getValue(asc ? b : a, idx));
    };
  }

  _getValue(tr, idx) {
    // use sort value to deal with formatted values
    const { dataset: { sortValue }, innerText, textContent } = tr.children[idx]
    return sortValue !== undefined ? sortValue : innerText || textContent
  }

  _sortDirection(asc, e) {
    this.tableElementTarget.querySelectorAll('th.cursor-pointer').forEach(th => {
      th.classList.remove('table-icon-sort-bottom', 'table-icon-sort-top', 'active')
    });
    if (asc) {
      e.currentTarget.classList.remove('table-icon-sort-bottom')
      e.currentTarget.classList.add('table-icon-sort-top', 'active')
    } else {
      e.currentTarget.classList.remove('table-icon-sort-top')
      e.currentTarget.classList.add('table-icon-sort-bottom')
    }
  }
}
