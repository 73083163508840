import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mainCheck", "subCheck", "counter", "importButton", "disabledButton" ]

  toggle(event){
    if (this.mainCheckTarget.checked) {
      this._setAllCheckboxesTo(true);
    } else {
      this._setAllCheckboxesTo(false);
    }
    this._setCounter();
  }

  updateCounter(event){
    this._setCounter();
  }

  // private
  _setAllCheckboxesTo(value){
    this.subCheckTargets.forEach((checkbox) => {
      checkbox.checked = value;
    })
  }

  _setCounter(){
    let counter = 0;
    this.subCheckTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        counter += 1;
      }
    })
    this.counterTarget.innerHTML = `(${counter})`

    if (counter == 0) {
      this.importButtonTarget.classList.add("hidden");
      this.disabledButtonTarget.classList.remove("hidden");
    } else {
      this.importButtonTarget.classList.remove("hidden");
      this.disabledButtonTarget.classList.add("hidden");
    }
  }

}
