import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["list", "template"]
  static values = { updateUrl: String }

  connect(){
    const stimulusThis = this;

    stimulusThis.listTargets.forEach(function(target){
      new Sortable(target, {
        group: 'task_template_group',
        sort: true,
        handle: '.task_template',
        animation: 150,
        onEnd: function (event) {
          stimulusThis._recalculatePositions();
        }
      })
    });
  }

  addNew(event){
    event.preventDefault();

    const toggleElement = document.querySelector(".toggle-descriptions-switch")
    const newIndex = document.querySelectorAll("tr.task_template").length
    const content = this.templateTarget.innerHTML.replace(/CHILD_INDEX/g, newIndex);

    const afterElement = this.listTargets[0];
    afterElement.insertAdjacentHTML('afterbegin', content)

    // If the descriptions toggle is switched off, we'll need to hide the description
    if (!toggleElement.checked) {
      afterElement.querySelector("tr.task_template .task_template_description").classList.add("hidden");
    }

    this._recalculatePositions();
  }

  delete(event){
    event.preventDefault();
    const parentTrElement = event.target.closest("tr")

    if (parentTrElement.classList.contains("js-dynamic")) {
      parentTrElement.parentNode.removeChild(parentTrElement);
    } else {
      parentTrElement.classList.add("hidden");
      parentTrElement.querySelector(".task_template_destroy_field").value="true";
    }
  }

  // private

  _recalculatePositions(){
    this.listTargets.forEach(function(list){
      list.querySelectorAll(".task_template_position_field").forEach(function(field, index_position){
        field.value = index_position;
      })
      list.querySelectorAll(".task_template_phase_blueprint_field").forEach(function(field){
        field.value = list.dataset.phaseBlueprintId;
      })
    });
  }

}
