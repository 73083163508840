import { Controller } from "@hotwired/stimulus";

/**
* This controller is used to autofocus an input element
* @markup-example:

<input type="text" name="name" data-controller="autofocus" />
**/

export default class extends Controller {
  connect(){
    this.element.focus()
  }
}
