import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "link" ]

  show(event){
    this.containerTarget.classList.remove("hidden");

    this.linkTargets.forEach(function(element){
      // We disable the links of the filters until they are reloaded
      // so they don't make another request while it's loading
      element.addEventListener('click',function(e){
         e.preventDefault();
         e.stopPropagation();
      });
    });

  }
}
