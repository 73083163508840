import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form" ]
  static values = { edit: Boolean }

  close(event){
    // These are checks to avoid sending the form unless we're absolutely certain we
    // are editing and clicking outside fields
    if (this.editValue &&
        event.target.closest(".inline-editable") === null &&
        document.activeElement === document.querySelector("body")) {
      Rails.fire(this.formTarget, 'submit');
    }
  }

  enableEditMode(event){
    // There might be more than one form in the page, we just enable the right one
    if (event.detail.formId === this.formTarget.id) {
      this.editValue=true;
    }
  }

}
