import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (!props.items.length) return null

  return (
    <div className="flex flex-col rounded border shadow bg-white">
      {props.items.map((item, index) => (
        <button key={index} onClick={() => selectItem(index)} className={`px-4 py-2 hover:bg-blue-100 text-left ${index === selectedIndex ? 'bg-blue-100' : ''}`}>
          {item}
        </button>
      ))}
    </div>
  );
});
