import { Controller } from "@hotwired/stimulus"
import AutoNumeric from 'autonumeric';

export default class extends Controller {
  static targets = ["field"]

  connect(){
    this.fieldTargets.forEach(function(target) {
      const element = document.querySelector(`#${target.id}`);
      if (!AutoNumeric.isManagedByAutoNumeric(element)){
        new AutoNumeric(element, {
          currencySymbol: ' €',
          currencySymbolPlacement: 's',
          decimalCharacter: ',',
          digitGroupSeparator: '.',
          unformatOnSubmit: true
        });
      }
    })
  }
}
