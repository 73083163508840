import { Controller } from "@hotwired/stimulus";

/**
* This controller is used to implement the follow up questions
* It copies the textContent of a given element into the input target
* @markup-example:

<div data-controller="follow-up-questions">
  <a href="#" data-action="click->follow-up-questions#handleClick">What is your name?</a>
  <input type="text" name="name" data-follow-up-questions-target="input" />
  <input type="submit" data-follow-up-questions-target="submit" />
</div>

**/

export default class extends Controller {
  static targets = ["input", "submit"];

  handleClick(event) {
    event.preventDefault();

    const question = event.currentTarget.textContent.trim();

    this.inputTarget.value = question;

    setTimeout(() => {
      this.submitTarget.click();
    }, 200);
  }
}
