import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "checkbox"]

  static values = {
    area: String
  }

  connect(){
  }

  // it might create and assign or toggle assign
  // the difference is in the URL passed down in the dataset.assignUrl. The rest is the same
  assign(event){
    const headers = this._getRequestHeaders();
    const body = this._getRequestBody(event.target);
    const url = event.target.dataset.assignUrl;

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  // private

  _getRequestHeaders(){
    let csrfToken;

    if (document.querySelector("meta[name='csrf-token']")) {
      csrfToken = document.querySelector("meta[name='csrf-token']").content
    }

    return {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    };
  }

  _getRequestBody(element){
    return {
      "tag": {
        "area": this.areaValue,
        "label": element.dataset.assignValue,
      }
    };
  }

}
