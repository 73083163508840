import { Controller } from "@hotwired/stimulus";

// - This controller is used when we want a select to not send an empty value for a given option
//
// Usage
// Targets:
// - select: The select input element
// - noValueOption: The option in the select that won't send an empty value when selected
//
// Sample usage in app/views/searches/index/sidebar/_filters.html.erb:30

export default class extends Controller {
  static targets = [ "select", "noValueOption"]

  connect() {
    this.selectTarget.addEventListener("focus", this._enableNoValueOption.bind(this));
    this.selectTarget.addEventListener("change", this._handleNoValueOption.bind(this));
  }

  disconnect() {
    this.selectTarget.removeEventListener("focus", this._enableNoValueOption.bind(this));
    this.selectTarget.removeEventListener("change", this._handleNoValueOption.bind(this));
  }

  disableNoValueOption() {
    this.noValueOptionTarget.disabled = true;
  }

  // private

  _enableNoValueOption() {
    this.noValueOptionTarget.disabled = false;
  }

  _handleNoValueOption() {
    if (this.selectTarget.value === this.noValueOptionTarget.value) {
      this.noValueOptionTarget.disabled = true;
    } else {
      this.noValueOptionTarget.disabled = false;
    }
  }
}
