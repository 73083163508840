import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

/**
 * This Stimulus controller is used to drag & drop elements in a list and save their position
 * It works with a form, so the submission of data is not the responsability of the JS, but the HTML.
 * The JS code just fires the submit of the form.
 *
 * Working sample: app/views/phase_blueprints/index.html.erb
 *
 * Targets:
 * - sortableGroup: The container element of the items we're going to drag & drop. For instance,
 *   if we're gonna reorder table <tr> tags, the target would be the <tbody>
 * - sortableElement: The hidden input field with the position for each element.
 *   This will be updated each time the list is reordered
 * - form: The form element that will be submitted after the inputs are updated.
 *
**/
export default class extends Controller {
  static targets = ['sortableGroup', 'sortableElement', "form"]

  connect(){
    const stimulusController = this;

    new Sortable(this.sortableGroupTarget, {
      sort: true,
      animation: 150,
      store: {
        set: function(sortable){
          stimulusController.sortableElementTargets.forEach(function(element, index){
            element.value = index;
          });

          Rails.fire(stimulusController.formTarget, 'submit');
        }
      }
    })
  }
}
