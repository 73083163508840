import { Controller } from "@hotwired/stimulus"

/*
This controller is used to add an animation
to show/hide a container.

Add this data attribute to the element where
you define the controller:
  @data-attribute: data-autohide-animation-value="animate-fade-out-right"

You can choose between these animations:
  - animate-fade-out-right
  - animate-fade-out-top

Markup:
  <article
    data-controller="autohide"
    data-autohide-target="container"
    data-autohide-animation-value="animate-fade-out-right"
  >
    <p>Autohide</p>
  </article>
*/

export default class extends Controller {
  static targets = ["container"]
  static values = {
    animation: {
      type: String,
      default: 'animate-fade-out-right'
    },
    seconds: {
      type: Number,
      default: 6
    }
  }

  connect(){
    setTimeout(() => {
      this._hide();
    }, this.secondsValue*1000);
  }

  // private
  _hide(){
    this.containerTarget.classList.add(this.animationValue);
  }

}
