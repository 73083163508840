// Controller to make a form submit itself whenever there's a change.
// Changes on the input must be addressed on the html itself with an action.
// For instance:
// <input type="checkbox" data-action="change->submit-on-change#submit">

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['form']

  submit(event){
    Rails.fire(this.formTarget, 'submit');
  }

}
