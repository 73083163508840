import { Controller } from "@hotwired/stimulus"

/**
 * This controller stores the user preferences in localStorage.
 * It is available throughout the full application, you only have to point what to store.
 * In order to do so, place the following data-attribute along any data-controller
 *
 * @data-attribute: data-user-preferences="<syntax>"
 *
 * The <syntax> follows this rule: "controller-identifier:static-value", where:
 * - "controller-identifier" is the controller the value belongs to
 * - "static-value" is the property we want to listen for user changes
 *
 * @example
 * <div
 *   id="unique-id"
 *   data-controller="toggle another-controller"
 *   data-user-preferences="toggle:contentOpened another-controller:property"
 * ></div>
 *
 * You can store multiple properties from multiple controllers.
 *
 * IMPORTANT: set an id for the HTMLElement you want to store the preference
 * in order to distinguish it from others choices of the same controller.
 * If there is no id, a console warning is thrown.
 *
 * The targeted controller requires to fetch the preferences to use the stored values.
 * We can use the helper function loadPreferences, and call it in the initialize function,
 * passing the controller itself (this) as argument
 *
 * @example
 * import { loadPreferences } from "./user_preferences_controller";
 * ...
 * initialize() {
 *  loadPreferences(this)
 * }
 */
export default class extends Controller {
  initialize() {
    // Use a Map to avoid object iterations (spread operator)
    const prefs = new Map(Object.entries(JSON.parse(localStorage.getItem("user-preferences")) || {}))

    // Since this controller is declared in the body tag, it will run before other controllers,
    // therefore, we must WAIT FOR them. How? With a microtask (better than setTimeout)
    // https://stimulus.hotwired.dev/reference/lifecycle-callbacks#order-and-timing
    Promise.resolve().then(() => this.addPreferenceListeners(this.element, prefs));

    // In case some parts of the HTML are delayed (they're lazy or fetch responses),
    // we must re-run the listeners to the new code blocks, to ensure the preferences are working inside them
    document.addEventListener("turbo:frame-render", event => this.addPreferenceListeners(event.target, prefs))
  }

  addPreferenceListeners(element, prefs) {
    const library = element.querySelectorAll("[data-user-preferences]")

    library.forEach(lib => {
      const props = lib.dataset["userPreferences"].split(" ")

      props.forEach(p => {
        const [identifier, prop] = p.split(":")
        const controller = this.application.getControllerForElementAndIdentifier(lib, identifier)

        if (controller) {
          // Unique identificator for the preference
          const id = setPreferenceId(identifier, lib.id)

          // Save the original ValueChanged function, in case it exists
          const originalValueChangedFunction = controller[`${prop}ValueChanged`]

          // Add listeners for property changes (no change, no preference, then: default value)
          controller[`${prop}ValueChanged`] = function (...args) {
            if (originalValueChangedFunction) {
              originalValueChangedFunction(...args)
            }

            const settings = prefs.get(id) || {}
            settings[prop] = args[0] // https://stimulus.hotwired.dev/reference/values#previous-values
            prefs.set(id, settings)

            localStorage.setItem("user-preferences", JSON.stringify(Object.fromEntries(prefs.entries())))
          };
        }
      })
    })
  }
}

function setPreferenceId(identifier, id) {
  // Each user preference is named by its controller identifier plus the markup id tag
  // Example: <div id="element-3" data-controller="name-ctl" /> will create "name-ctl-element-3"
  return [identifier, id].join("-")
}

export function loadPreferences(controller) {
  // Show a warning when HTMLElement has no id tag, preferences cannot be saved if they have not any id
  if (!controller.element.id) {
    return console.warn("Add an id to this HTMLElement in order to save the preferences", controller.element);
  }

  const prefId = setPreferenceId(controller.identifier, controller.element.id)
  const prefs = JSON.parse(localStorage.getItem("user-preferences"))?.[prefId]

  if (prefs) {
    // Each controller can save multiple properties
    Object.entries(prefs).forEach(([key, value]) => controller[`${key}Value`] = value)
  }
}
