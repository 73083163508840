import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// - This controller automatically updates the address bar & history
//   in the browser with a new url. No action needed, it does it on connect.
// - It's useful when the content of a page is updated with turbo stream
//   want the url to be updated.
// - Placing this controller inside the HTML that's being refreshed will do the trick.
//
// How to:
// <div
//   data-controller="url-refresher"
//   data-url-refresher-url-value="http://..."
// >
// ...
// </div>
//
// Working sample on app/views/searches/shared/_filters_summary.html.erb

export default class extends Controller {
  static values = { url: String }

  connect(){
    this._maybeUpdateUrl();
  }

  _maybeUpdateUrl(){
    // It won't update the url if it's the same.
    if (!window.location.href.endsWith(this.urlValue)){
      history.pushState(null, null, this.urlValue)
    }
  }
}
