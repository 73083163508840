import { Controller } from "@hotwired/stimulus";
import { create, registerPlugin } from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.css';

export default class extends Controller {
  static targets = [ "fileField" ]
  static values = { labelIdle: String }

  connect() {
    registerPlugin(FilePondPluginFileValidateType);

    const input = this.fileFieldTarget;

    let labelIdle;
    if (this.hasLabelIdleValue) {
      labelIdle = this.labelIdleValue;
    } else {
      labelIdle = I18n.droppable.label_idle_html;
    }


    create(input, {
      storeAsFile: true,
      credits: false,
      labelIdle: labelIdle,
      allowProcess: false,
      fileValidateTypeLabelExpectedTypes: I18n.droppable.file_validate_expected_types_label,
      acceptedFileTypes: [
        "application/pdf",
        "text/plain", "text/csv",
        "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/odt", "application/vnd.oasis.opendocument.text",
      ]
    });
  }
}
