import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    window.addEventListener("load", async () => {
      // check if the browser supports serviceWorker at all
      if ("serviceWorker" in navigator) {
        // eslint-disable-next-line no-unused-vars
        const registration = await navigator.serviceWorker.register('/service-worker.js', { scope: "/" });
      } else {
        console.log("Your browser doesn't support service workers 🤷‍♀️");
      }
    });
  }
}
