import { timeFormat, utcFormat } from "d3-time-format";

const days = {
  YEAR: 1460, // show the past 4 years
  MONTH: 1080, // show the past 36 months
  QUARTER: 1440, // show the past 16 quarters
};

const filterByYears = groupByDays(days.YEAR, "year");
const filterByQuarter = groupByDays(days.QUARTER, "quarter");
const filterByMonth = groupByDays(days.MONTH, "month");

export const CONTRACT_TYPES = {
  0: {
    en: "private_contract",
    es: "Privado",
    ca: "Privat",
  },
  1: {
    en: "patrimonial",
    es: "Patrimonial",
    ca: "Patrimonial",
  },
  2: {
    en: "services",
    es: "Servicios",
    ca: "Serveis",
  },
  3: {
    en: "public_services_management",
    es: "Gestión de Servicios Públicos",
    ca: "Gestió de Serveis Públics",
  },
  4: {
    en: "construction",
    es: "Obras",
    ca: "Obres",
  },
  5: {
    en: "special_administrative",
    es: "Administrativo especial",
    ca: "Administratiu especial",
  },
  6: {
    en: "public_works",
    es: "Concesión de Obras públicas",
    ca: "Concessió d'Obres públiques",
  },
  7: {
    en: "supplies",
    es: "Suministros",
    ca: "Subministraments",
  },
  8: {
    en: "public_private_collaboration",
    es: "Colaboración entre el sector público y sector privado",
    ca: "Col·laboració entre el sector públic i sector privat",
  },
  9: {
    en: "other",
    es: "Otros",
    ca: "Altres",
  },
  10: {
    en: "unknown_contract_type",
    es: "Desconocido",
    ca: "Desconegut",
  },
};

export const PROCESS_TYPES = {
  0: {
    en: "internal_rules",
    es: "Normas Internas",
    ca: "Normes Internes",
  },
  1: {
    en: "open",
    es: "Abierto",
    ca: "Obert",
  },
  2: {
    en: "restricted",
    es: "Restringido",
    ca: "Restringit",
  },
  3: {
    en: "negotiated_without_publicity",
    es: "Negociado sin publicidad",
    ca: "Negociat sense publicitat",
  },
  4: {
    en: "negotiated_with_publicity",
    es: "Negociado con publicidad",
    ca: "Negociat amb publicitat",
  },
  5: {
    en: "competitive_dialogue",
    es: "Diálogo competitivo",
    ca: "Diàleg competitiu",
  },
  6: {
    en: "minor_contract",
    es: "Contrato menor",
    ca: "Contracte menor",
  },
  7: {
    en: "based_on_agreement",
    es: "Basado en Acuerdo Marco",
    ca: "Basat en Acord Marc",
  },
  8: {
    en: "project_competition",
    es: "Concurso de proyectos",
    ca: "Concurs de projectes",
  },
  9: {
    en: "open_simplified",
    es: "Abierto simplificado",
    ca: "Obert simplificat",
  },
  10: {
    en: "innovation",
    es: "Asociación para la innovación",
    ca: "Associació per a la innovació",
  },
  11: {
    en: "innovation_derived",
    es: "Derivado de asociación para la innovación",
    ca: "Derivat d'associació per a la innovació",
  },
  12: {
    en: "dynamic_acquisition",
    es: "Basado en sistema dinámico de adquisición",
    ca: "Basat en sistema dinàmic d'adquisició",
  },
  13: {
    en: "negotiated",
    es: "Licitación con negociación",
    ca: "Licitació amb negociació",
  },
  14: {
    en: "others",
    es: "Otros",
    ca: "Altres",
  },
  15: {
    en: "unknown_process_type",
    es: "Desconocido",
    ca: "Desconegut",
  },
};

export const GOBIERTO_CONTRACTOR_ENTITY_TYPES = {
  0: {
    en: "age",
    es: "AGE",
    ca: "AGE",
  },
  1: {
    en: "ccaa",
    es: "CCAA",
    ca: "CCAA",
  },
  2: {
    en: "local",
    es: "Entidades locales",
    ca: "Entitats locals",
  },
};

export const NUMBER_OF_PROPOSALS = {
  0: {
    en: "0",
    es: "Desiertos",
    ca: "Deserts",
  },
  1: {
    en: "1",
    es: "1 licitador",
    ca: "1 licitador",
  },
  2: {
    en: "2",
    es: "2 licitadores",
    ca: "2 licitadors",
  },
  3: {
    en: "3",
    es: "3 licitadores",
    ca: "3 licitadors",
  },
  4: {
    en: "4",
    es: "4 licitadores",
    ca: "4 licitadors",
  },
  5: {
    en: "5",
    es: "5 licitadores",
    ca: "5 licitadors",
  },
  6: {
    en: "6",
    es: "6 o más licitadores",
    ca: "6 o més licitadors",
  },
};

export const INITIAL_AMOUNT_RANGE_VALUES = {
  0: "<=15k",
  1: "15-30k",
  2: "30-50k",
  3: "50-100k",
  4: "100-200k",
  5: "200-500k",
  6: "500-1M",
  7: ">1M",
};

//Generates an array with dates as a string.
export function groupByDays(days, type = "") {
  const TENTH_DAY = 10;
  const OCTOBER_JS = 9;
  const ADD_ONE_TO_MONTH = 1;
  return [...Array(days)]
    .map((_, index) => {
      const omitDays = type === "month" ? 30 + index : type === "quarter" ? 90 + index : index;
      const dates = new Date();
      dates.setDate(dates.getDate() - omitDays);
      return dates;
    })
    .map((d) => {
      //Add a zero to those days with only one digit
      const getStringDay = d.getDate() < TENTH_DAY ? `0${d.getDate()}` : d.getDate();
      //Before converting the dates into strings, let's add a zero to those months with only one digit.
      //We have to add one to the month because month's ranges JS starts at 0(January) and end at 11(December).
      const getStringMonth =
        d.getMonth() < OCTOBER_JS ? `0${d.getMonth() + ADD_ONE_TO_MONTH}` : d.getMonth() + ADD_ONE_TO_MONTH;
      return `${d.getFullYear()}-${getStringMonth}-${getStringDay}`;
    });
}

//Split the dates into quarter
export function getQuarter(d) {
  const newDate = new Date(d);
  const currentQuarter = Math.floor(newDate.getMonth() / 3) + 1;
  const firstMonthQuarter = currentQuarter * 3 - 2;

  // Since this literal will be parsed as Date, gecko browsers returns "Invalid date" for new Date("2020-1"),
  // due to the chart will show only the year, we introduce a valid year-month value,
  // beginning with the first month of such quarter (Jan, Apr, Jul, Oct)
  return `${newDate.getFullYear()}-${firstMonthQuarter.toString().padStart(2, "0")}`;
}

export function timeFormatDate(typeOfDate) {
  return typeOfDate === "month"
    ? (d) => timeFormat("%b-%Y")(d)
    : typeOfDate === "year"
    ? (d) => timeFormat("%Y")(d)
    : (d) => `T${utcFormat("%q %Y")(d)}`;
}

// Translate enums to strings.
export function parseDataTypes(data) {
  const lang = document.documentElement.lang;

  return data.reduce((acc, item) => {
    const contract_type = CONTRACT_TYPES[item.contract_type]?.[lang] || item.contract_type;
    const gobierto_contractor_entity_type =
      GOBIERTO_CONTRACTOR_ENTITY_TYPES[item.gobierto_contractor_entity_type]?.[lang] || item.gobierto_contractor_entity_type;
    const process_type = PROCESS_TYPES[item.process_type]?.[lang] || item.process_type;
    const initial_amount_range = INITIAL_AMOUNT_RANGE_VALUES[item.initial_amount_range] || item.initial_amount_range;
    const number_of_proposals = NUMBER_OF_PROPOSALS[item.number_of_proposals]?.[lang] || item.number_of_proposals;

    acc.push({
      ...item,
      contract_type,
      gobierto_contractor_entity_type,
      process_type,
      initial_amount_range,
      number_of_proposals,
    });

    return acc;
  }, []);
}

export function getDateRange(dateSelector) {
  // from the established time ranges (see at the top),
  // calculate all the possible dates between them, formatted accordingly
  const allExistingTimeValues = {
    year: [...new Set(filterByYears.map((date) => new Date(date).toISOString().slice(0, 4)))],
    month: [...new Set(filterByMonth.map((date) => new Date(date).toISOString().slice(0, 7)))],
    quarter: [...new Set(filterByQuarter.map((date) => getQuarter(new Date(date))))],
  };

  return allExistingTimeValues[dateSelector].sort()
}

export function isDataTotalEmpty(data) {
  return data.every(({ total }) => total === 0);
}

export function sortingTooltipFn(type) {
  // Some types have a different sorting method than default one
  const sortingFn = {
    "initial_amount_range": d => {
      const arr = Object.values(INITIAL_AMOUNT_RANGE_VALUES)
      return d.sort(({ name: a }, { name: b }) => arr.indexOf(a) - arr.indexOf(b))
    },
    "number_of_proposals": d => {
      const arr = Object.values(NUMBER_OF_PROPOSALS).map(x => x[document.documentElement.lang])
      return d.sort(({ name: a }, { name: b }) => arr.indexOf(a) - arr.indexOf(b))
    }
  }

  // double sort: first, by count, then, by sum
  const defaultSortingFn = d => d.sort(({ count: a, sum: _a }, { count: b, sum: _b }) => (a === b ? _b - _a : b - a))

  return sortingFn[type] || defaultSortingFn
}
