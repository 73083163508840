import { Controller } from "@hotwired/stimulus"

// - This controller is used when we required all values marked as "requiredElement" target only
//   if just one of them is filled. If none is filled, we won't required anything.
//
// Usage
// Targets:
// - checkbox: The checkbox input that will control the others
// - requiredElement: The inputs that will be codependent.
//
// Sample usage in app/views/internal_contracts/shared/form/_extensions.html.erb

export default class extends Controller {
  static targets = [ "requiredElement" ]

  connect(){
    this._handleCurrentStatus();
  }

  toggleRequired(event) {
    this._handleCurrentStatus();
  }

  // private

  _handleCurrentStatus(){
    const anySelected = this.requiredElementTargets.some((element) =>{
      return (element.value != "" && element.value != undefined)
    })

    if (anySelected) {
      this._addRequired();
    } else {
      this._removeRequired();
    }
  }

  _addRequired(){
    this.requiredElementTargets.forEach(function(element){
      element.required = true;
    });
  }

  _removeRequired(){
    this.requiredElementTargets.forEach(function(element){
      element.removeAttribute("required");
    });
  }
}
