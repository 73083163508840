import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleContainer", "stepOneContainer", "stepTwoContainer",
    "projectIdField", "withoutCommentButton", "withCommentButton", "toggleButton",
    "projectElement" ]

  forward(event){
    event.preventDefault();

    this.stepOneContainerTarget.classList.add("hidden");
    this.stepTwoContainerTarget.classList.remove("hidden");

    this.projectIdFieldTarget.value = event.target.dataset.projectId
  }

  backward(event){
    event.preventDefault();

    this.stepOneContainerTarget.classList.remove("hidden");
    this.stepTwoContainerTarget.classList.add("hidden");
    const textArea = document.getElementById("clip_body");
    textArea.value = ""
  }

  filter(event){
    const currentText = event.target.value.toLowerCase();

    this.projectElementTargets.forEach(function(target){
      if (target.hasAttribute("data-filter-text")) {
        if (target.dataset.filterText.toLowerCase().match(currentText)) {
          target.classList.remove("hidden");
        } else{
          target.classList.add("hidden");
        }
      }
    })
  }

  handleButtonsState(event){
    if (event.target.value === "") {
      this.withoutCommentButtonTarget.removeAttribute("disabled")
      this.withCommentButtonTarget.setAttribute("disabled", "disabled")
    } else {
      this.withoutCommentButtonTarget.setAttribute("disabled", "disabled")
      this.withCommentButtonTarget.removeAttribute("disabled")
    }
  }
}
