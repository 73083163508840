import TextareaAutogrow from 'stimulus-textarea-autogrow'

export default class extends TextareaAutogrow {

  autogrow () {
    this.element.style.height = 'auto'
    let height = Math.max(70, this.element.scrollHeight)
    this.element.style.height = `${height}px`
  }

}
