import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";
import OnlineEditor from "../react/online-editor/OnlineEditor.jsx";
import { replaceShortcuts } from "../react/online-editor/components/extension-variable.js";

export default class extends Controller {
  static values = {
    clauseParams: Object
  }

  initialize() {
    this.element.addEventListener("focusin", this.handleFocusIn.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("focusin", this.handleFocusIn.bind(this))
  }

  handleFocusIn() {
    // find all "online-editor" controllers in the document
    const controllers = this.application.controllers.filter(({ identifier }) => identifier === this.identifier)

    // remove all active status...
    controllers.forEach(({ element }) => {
      element.classList.remove("last-active")
      element.lastActive = false // use a custom prop for easier handling
    })
    // ...but the current one
    this.element.classList.add("last-active")
    this.element.lastActive = true
  }

  connect() {
    // TODO: just for testing purposes
    this.content = JSON.parse(localStorage.getItem(this.element.id))

    if (!this.content) {
      // NOTE: replaceShortcuts only changes "@@VARIABLE@@" plain values to nodes
      // It must run JUST for HTML, not JSON
      this.content = replaceShortcuts(this.element.innerHTML)
      // after saving that value, it's necessary to clean all children (duplicate texts)
      this.element.innerHTML = ""
    }

    const root = createRoot(this.element);
    root.render(<OnlineEditor container={this.element} content={this.content} {...this.clauseParamsValue} />);
  }
}
