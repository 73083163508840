// This controller will replace the inner text on the button that's clicked
// with a "Loading..." text. It's useful when the button perfom an async action.
//
// It also looks for a spinner with the class "js-spinner-loading" and toggles it
// if exists.
//
// Additionally, it looks for a target with the data target set "tohide" and toggles it
//
// Usage:
//   <div data-controller="loading">
//     <button data-action="click->loading#switch">Guardar</button>
//     <div class="js-spinner-loading hidden">...</div>
//     <div id="element-to-hide" data-loading-target="tohide">This element will get hidden when the switch() is called</div>
//   </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "tohide" ]

  switch(event) {
    const button = event.target;

    button.innerHTML = I18n.loading.text;

    const spinner = document.querySelector(".js-spinner-loading");
    if (spinner) {
      spinner.classList.toggle("hidden");
      if (this.hasTohideTarget) {
        this.tohideTarget.classList.toggle("hidden");
      }
    }
  }
}
