import { Controller } from "@hotwired/stimulus"

/**
 * This controller listens for an sticky element,
 * and appends the "is-stuck" class to the HTMLElement,
 * as long as being active. In that way, it's possible
 * to distinguish both status
 *
 * Add this attribute to change the sticky selector:
 * @data-attribute: data-sticky-selector-value=".other-selector"
 *
 * @markup-example:
 * <article class="mt-8" data-controller="sticky">
    <nav class="sticky top-0 hidden [.is-stuck_&]:block">...</nav>
    ...
   </article>
 */
export default class extends Controller {
  static values = {
    selector: {
      type: String,
      default: ".sticky"
    }
  }

  connect(){
    const stickies = document.querySelectorAll(this.selectorValue);

    stickies.forEach((element) =>
      window.addEventListener("scroll", () => {
        const stickyTop = parseInt(getComputedStyle(element).top);
        const currentTop = element.getBoundingClientRect().top;
        element.classList.toggle("is-stuck", currentTop <= stickyTop);
      })
    );
  }
}
