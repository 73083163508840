import { select } from "d3-selection";
import { scaleBand, scaleTime } from "d3-scale";
import { axisTop } from "d3-axis";
import { timeMonth } from "d3-time";
import "d3-transition";
import { timeFormat, timeFormatDefaultLocale } from "d3-time-format";

const locales = {
  es: () => import("d3-time-format/locale/es-ES.json"),
  ca: () => import("d3-time-format/locale/ca-ES.json"),
}

// load only the current set of translations
locales[document.documentElement.lang]().then(({ default: lang }) => timeFormatDefaultLocale(lang))

export default class Chronogram {
  constructor(node, animation, { x, y, margins }) {

    const { width, height } = node.getBoundingClientRect()

    this.margins = { top: 20, left: 20, bottom: 0, right: 20, ...margins }
    this.xProp = x ?? "x"
    this.yProp = y ?? "y"
    this.width = width - this.margins.left - this.margins.right
    this.height = height - this.margins.top - this.margins.bottom
    this.animation = animation

    this.svg = select(node)
      .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
  }

  xAxis(g) {
    // Calculates the total span, in such way that the ticks will be monthly
    // for spans shorter than one year, bimonthly for shorter than two years, and so on...
    const numTicks = timeMonth.every(Math.ceil(timeMonth.count(...this.x.domain()) / 12))
    g.call(
      axisTop(this.x).ticks(numTicks).tickFormat(timeFormat("%b"))
    );
    g.select(".domain").remove();
    g.selectAll(".tick line")
      .attr("opacity", 0.2)
      .attr("stroke-dasharray", "3 6")
      .attr("y2", this.height)
    g.selectAll(".tick text")
      .attr("class", "uppercase font-sans text-xs text-black")
  }

  extent(data) {
    return data.reduce(
      ([ min, max ], item) => ([
        !min || item < min ? item : min,
        !max || item > max ? item : max
      ]),
      [ null, null ]
    );
  }

  build(raw) {
    // enforce the data to be a Date
    const data = raw.map(({ [this.xProp]: x, [this.yProp]: y }) => ({ [this.xProp]: new Date(x), [this.yProp]: new Date(y) }))

    this.x = scaleTime()
      .range([0, this.width])
      .domain(this.extent(data.flatMap(d => [d[this.xProp], d[this.yProp]])))
      .nice()

    this.y = scaleBand()
      .range([0, this.height])
      .domain(Array.from({ length: data.length }, (_, i) => i))

    this.g = this.svg
      .append("g")
      .attr("transform", `translate(${this.margins.left}, ${this.margins.top})`)

    this.g.append("g").call(this.xAxis.bind(this))

    this.g = this.g
      .append("g")
      .attr("transform", `translate(0, ${this.margins.top})`)
      .selectAll("line")
      .data(data)
      .join("line")

    this.g
      .attr("stroke", "currentColor")
      .attr("stroke-width", this.y.bandwidth() / 4)
      .attr("stroke-linecap", "round")
      .attr("x1", d => this.x(d[this.xProp]))
      .attr("x2", d => this.x(d[this.xProp]))
      .attr("y1", ({ shadow }, i) => !!shadow ? this.y(i-1) : this.y(i))
      .attr("y2", ({ shadow }, i) => !!shadow ? this.y(i-1) : this.y(i))
      .attr("class", ({ shadow }) => `text-gobpink ${!!shadow ? "text-opacity-20" : ""}`)
      .call(this._chronogramAnimation.bind(this))
  }

  _chronogramAnimation() {
    if(this.animation) {
      this.g
        .transition()
        .delay(({ shadow }, i) => !!shadow ? (i - 1) * 200 : i * 200)
        .attr("x2", d => this.x(d[this.yProp]))
        .attr("stroke-width", ({ shadow }) => !!shadow ? this.y.bandwidth() / 1.5 : this.y.bandwidth() / 4)
    } else {
      this.g
        .attr("x2", d => this.x(d[this.yProp]))
        .attr("stroke-width", ({ shadow }) => !!shadow ? this.y.bandwidth() / 1.5 : this.y.bandwidth() / 4)
    }
  }

}
