import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['checkbox', 'container', "disableElement"]
  static values = { confirmMessage: String }

  delete(event){
    event.preventDefault();

    let confirmed = confirm(this.confirmMessageValue)

    if (confirmed) {
      this.containerTarget.classList.add("hidden")

      this.disableElementTargets.forEach(function(element){
        element.disabled = true;
      });

      if (this.hasCheckboxTarget) {
        this.checkboxTarget.value = "1";
      }
    }
  }

}
