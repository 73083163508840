import { Controller } from "@hotwired/stimulus"

// This controller implements the logic of the global search.
// Simply adds the query string to the URL and redirects to the search page.

export default class extends Controller {
  search() {
    const formData = Object.fromEntries(new FormData(this.element))
    Turbo.visit(`${this.element.action}?${new URLSearchParams(formData).toString()}`)
  }
}
