import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  microtipCopiedMessage({ target }) {
    const elem = target.querySelector('[role="tooltip"]')
    if (elem) {
      elem.setAttribute("aria-label", I18n.events.copied)
    }
  }
}
