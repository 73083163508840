import { Controller } from "@hotwired/stimulus";
import { loadPreferences } from "./user_preferences_controller";

/**
 * Tabs Controller
 *
 * Usage:
 *   1. Add an id to the container element
 *      e.g., <article id="control" data-controller="tabs">
 *   2. Add these attributes to tab links:
 *      - data-tabs-target="tabItem"
 *      - data-action="click->tabs#showTab"
 *      - data-tabs-controls-param="anchor_name"
 *   3. Add these attributes to tab contents:
 *      - id="anchor_name"
 *      - data-tabs-target="tabPanel"
 *
 * Options:
 *   - data-tabs-update-url-value="false" (don't update URL)
 *   - data-tabs-all-hidden-value="true" (all tabs hidden initially)
 *   - data-user-preferences="tabs:currentTab" (save the current tab via user_preferences_controller)
 *   - data-tabs-default-tab-value="tab1"
 *
 * Markup Example:
 *
 * <article id="control" data-controller="tabs">
 *   <nav>
 *     <a data-tabs-target="tabItem" data-action="click->tabs#showTab" data-tabs-controls-param="tab1">Tab 1</a>
 *     <a data-tabs-target="tabItem" data-action="click->tabs#showTab" data-tabs-controls-param="tab2">Tab 2</a>
 *   </nav>
 *
 *   <section>
 *     <div id="tab1" data-tabs-target="tabPanel">Content 1</div>
 *     <div id="tab2" data-tabs-target="tabPanel">Content 2</div>
 *   </section>
 * </article>
 */

export default class extends Controller {
  static targets = ["tabItem", "tabPanel"];
  static values = {
    currentTab: String,
    updateUrl: {
      type: Boolean,
      default: true,
    },
    allHidden: {
      type: Boolean,
      default: false,
    },
  };

  initialize() {
    loadPreferences(this);
  }

  connect() {
    // Show a warning when HTMLElement has no id tag
    if (this.updateUrlValue && !this.element.id) {
      return console.warn(
        "Add an id to this HTMLElement in order to update the URL",
        this.element
      );
    }

    // a11y
    this.tabItemTargets.forEach((tab) => {
      tab.role = "tab";
      tab.setAttribute("aria-controls", tab.dataset.tabsControlsParam);
    });
    this.tabPanelTargets.forEach((tab) => (tab.role = "tabpanel"));

    this.initTabs();
  }

  initTabs() {
      // if the URL contains a hash
      const activeTab = new URL(window.location.href).searchParams.get(
        `${this.element.id}_tab`
      );
      if (activeTab) {
        return this._showTabSelected(activeTab);
      }
  
      // if the tab is explicitly set by the arguments (values)
      if (this.hasCurrentTabValue) {
        return this._showTabSelected(this.currentTabValue);
      }
  
      // if the tab is specified by data-tabs-default-tab-value
      const defaultTab = this.element.dataset.tabsDefaultTabValue;
      if (defaultTab) {
        return this._showTabSelected(defaultTab);
      }
  
      // otherwise, the first tab is shown.
      return this.showTab(this.tabPanelTargets[0], this.tabPanelTargets[0].id);
    }

  _showTabSelected(hash) {
    const tab = this.tabItemTargets.find((target) =>
      target.dataset.tabsControlsParam.includes(hash)
    );
    tab && this.showTab(tab, hash);
  }

  showTab(event = {}, activeTab = "") {
    const { type, params } = event;

    if (type === "click") {
      event.preventDefault();
    }

    this.currentTabValue = type === "click" ? params.controls : activeTab;

    if (this.allHiddenValue) {
      // Toggle the active panel visibility
      this.tabPanelTargets.forEach((element) => {
        if (element.id === this.currentTabValue) {
          element.hidden = !element.hidden;
        } else {
          element.hidden = true;
        }
      });
    } else {
      // Toggle the active panel
      this.tabPanelTargets.forEach((element) => {
        element.hidden = element.id !== this.currentTabValue;
      });
    }

    // Toggle the active tab
    this.tabItemTargets.forEach((tab) =>
      tab.setAttribute(
        "aria-selected",
        tab.dataset.tabsControlsParam === this.currentTabValue
      )
    );

    if (this.updateUrlValue) {
      this.setUpdateUrl();
    }

    if (document.getElementById(`${this.element.id}_tab`)) {
      document.getElementById(`${this.element.id}_tab`).value =
        this.currentTabValue;
    }
  }

  setUpdateUrl() {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(`${this.element.id}_tab`, this.currentTabValue);

    history.replaceState(null, null, `?${queryParams.toString()}`);
  }
}
