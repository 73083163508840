import { Controller } from "@hotwired/stimulus"

/*
* This controller is for filtering a list of items while typing in an input.
*
* Add these attributes where you define the data-controller:
*  @data-attribute: data-controller="filter-input"
* Here we define the array with the list of items to be filtered:
*  @data-attribute: data-filter-input-array-value="<%= data.map { |t| t.name }.to_json %>"
* Adds this attribute to each list item:
*  @data-attribute: data-filter-input-target="option"
*  @data-attribute: data-element="<%= administrative_manager.name %>"
*
* @markup-example:
*
* <div class="table-top-filter"
*   data-controller="filter-input"
*   data-filter-input-array-value="<%= current_org_users.map { |t| t.name }.to_json %>"
* >
* <%= render partial: "shared/filter_values_input" %>
* <ul>
*   <% current_org_users.each do |administrative_manager| %>
*   <li
*     tabindex="0"
*     data-filter-input-target="option"
*     data-element="<%= administrative_manager.name %>"
*   >
*     <%= administrative_manager.name %>
*   </li>
*   <% end %>
* </ul>
* </div>

*/

export default class extends Controller {
  static targets = ["input", "option", "createOption"]

  static values = {
    array: {
      type: Array,
      default: []
    }
  }

  filter(event) {
    const { target: { value } } = event
    this.filteredData = this.arrayValue.filter(item => item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
    this.optionTargets.forEach(option => {
      const { dataset: { element } } = option;
      if (this.filteredData.includes(element)) {
        option.classList.remove("hidden")
      } else {
        option.classList.add("hidden")
      }
    })

    if(value === ""){
      this.optionTargets.forEach(option => option.classList.remove("hidden"))
    }

    if (this.hasCreateOptionTarget) {
      this._handleCreateOption(value);
    }
  }

  // private

  _handleCreateOption(value){
    if (value === "" || this.optionTargets.map((item) => item.dataset.element.toLowerCase()).includes(value.toLowerCase())){
      this.createOptionTarget.classList.add("hidden");
    } else {
      this.createOptionTarget.innerHTML = this._creationLabel(value)
      this.createOptionTarget.dataset.assignValue = value;
      this.createOptionTarget.classList.remove("hidden");
    }
  }

  _creationLabel(value){
    return this.createOptionTarget.dataset["createLabel"].replace("REPLACE", `"${value}"`)
  }
}
