import { Controller } from "@hotwired/stimulus";
import AutoNumeric from 'autonumeric';

export default class extends Controller {
  static targets = [ "template", "selector", "singleBatchContainer", "batchesContainer", "batchBlock", "amountTotalField" ];

  connect(){
    this.updateBatches();
    this.updateTotal();
  }

  updateBatches(event){
    if (this.selectorTarget.value != "1") {
      this.singleBatchContainerTarget.classList.add("hidden")
      this.batchesContainerTarget.classList.remove("hidden")
      this._updateBlocks(parseInt(this.selectorTarget.value));
    } else {
      this.singleBatchContainerTarget.classList.remove("hidden")
      this.batchesContainerTarget.classList.add("hidden")
    }
  }

  updateTotal(event){
    let sum = 0;
    this.batchesContainerTarget.querySelectorAll(".js-amount-batch").forEach(function(amountField){
      if (amountField.value && amountField.value !== '' && AutoNumeric.getAutoNumericElement(amountField) !== null) {
        sum += AutoNumeric.getNumber(amountField)
      }
    });

    AutoNumeric.set(this.amountTotalFieldTarget, sum);
  }

  // private

  _updateBlocks(batchesNumber){
    if (this.batchBlockTargets.length > batchesNumber) {
      const batchesToRemove = this.batchBlockTargets.length - batchesNumber;
      this._removeBlocks(batchesToRemove);
    } else if (this.batchBlockTargets.length < batchesNumber) {
      const batchesToAdd = batchesNumber - this.batchBlockTargets.length;
      this._addBlocks(batchesToAdd);
    }
  }

  _removeBlocks(batchesToRemove){
    // We remove the last one batchesToRemove times
    for (var i = 0; i < batchesToRemove; i++) {
      const elem = this.batchBlockTargets[this.batchBlockTargets.length-1];
      elem.parentNode.removeChild(elem);
    }
  }

  _addBlocks(batchesToAdd){
    const startingBatch = this.batchBlockTargets.length + 1;

    for(let i=0; i < batchesToAdd; i++) {
      const currentBatchNumber = startingBatch + i;
      this._insertBlock(currentBatchNumber)
    }
  }

  _insertBlock(currentBatchNumber){
    const content = this.templateTarget.innerHTML.replace(/BATCH_NUMBER/g, currentBatchNumber)

    if (this.hasBatchBlockTarget) {
      this.batchBlockTargets[this.batchBlockTargets.length-1].insertAdjacentHTML('afterend', content)
    } else {
      this.batchesContainerTarget.insertAdjacentHTML('afterbegin', content)
    }
  }

}
