import { Controller } from "@hotwired/stimulus";
import { toJSON } from "gobierto-vizzs";

export default class extends Controller {

  static values = {
    csvEndpoint: String
  };

  async connect() {
    const response = await fetch(this.csvEndpointValue).then((r) => r.text());
    const data = this.csvEndpointValue ? toJSON(response) : response;

    this.dispatch("data", { detail: data })
  }
}
