import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
  }

  toggleSidebar(event){
    event.preventDefault();
    const category_id = event.target.dataset.categoryId;
    const cpvs_ul = document.querySelector(`ul[data-category-id="${category_id}"]`)
    cpvs_ul.classList.toggle("hidden");
  }

  goToCpv(event){
    const cpv_id = event.target.value
    const url = `/cpvs/${cpv_id}`

    window.location.href = url;
  }

}
