import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

const maxSearchTerms = 10;

export default class extends Controller {
  static targets = [ "searchTerm", "noAccessContainer", "addSearchTermLink" ]

  connect(){
    this._focusLast();
  }

  addSearchTerm(event){
    event.preventDefault();

    if (this._getSearchTermsCount() < maxSearchTerms) {
      const newSearchTerm = this.searchTermTarget.cloneNode(true);
      newSearchTerm.value = "";
      this.searchTermTargets[this.searchTermTargets.length-1].insertAdjacentElement('afterend', newSearchTerm);
      this._focusLast();

      if (this._getSearchTermsCount() == maxSearchTerms) {
        this.addSearchTermLinkTarget.innerHTML = '';
      }
    }
  }

  showNoAccess(event){
    event.preventDefault();
    this.noAccessContainerTarget.classList.remove("hidden");
  }

  // private

  _focusLast(){
    const focusInput = this.searchTermTargets[this.searchTermTargets.length-1];

    const currentValue = focusInput.value;
    focusInput.focus();
    // We remove and add the value to the focus cursor is placed in the end of the input
    focusInput.value = '';
    focusInput.value = currentValue;
  }

  _getSearchTermsCount(){
    return document.querySelectorAll(".js-search-term").length;
  }
}
