// - This controller is for opening modals outside the scope of the ModalController
// - To achieve that, this relies on custom events.
// - Since there might be several modals on the same page, the modal and the modal-opener controllers
//   both define the same openEventName value. When the event is sent, the openOpenValue is aded too.
//   If it matches in the modal controller, it'll open.

// Example data attributes code for a link:
//    data: {
//      controller: "modal-opener",
//      modal_opener_open_event_name_value: "share",
//      action: "click->modal-opener#sendOpenEvent"
//    }
// Example data atribute value code in the modal:
//    data-modal-open-event-name-value="share"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    openEventName: String
  }

  sendOpenEvent(event) {
    event.preventDefault();
    window.dispatchEvent(new CustomEvent("openModal", {
      detail: {
        openEventName: this.openEventNameValue
      }
    }));
  }
}
